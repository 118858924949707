export const REQUEST_ID_URL_PATH = "/person";
export const RECOGNIZE_PHOTOS_URL_PATH = "/photos";
export const REQUEST_URL_PATH = "/request";
export const CREATE_PAYMENT_URL_PATH = "/payment";
export const PRODUCT_URL_PATH = "/product";
export const STATUS_URL_PATH = "/payment_status";
export const FACECHECK_URL_PATH = "/face";
export const COUPON_URL_PATH = "/coupon";
export const RESULTS_URL_PATH = "/results";
export const SPEC_RESULT_URL_PATH = "/result";

export const PRODUCTS_URL_PATH = "/products";

export const FEEDBACK_URL_PATH = "/feedback";

export const ACTIVITY_STATS_URL_PATH = "/activity_stats";

export const YOOKASSA_URL_PATH = "https://yookassa.ru/checkout-widget/v1/checkout-widget.js";

export const RESULTS_FRONTEND_PATH = "/results";
export const COUPON_FRONTEND_PATH = "/coupon";
export const FIND_FRONTEND_PATH = "/find";
