import React, { useState, useRef, useEffect } from 'react';
import styles from "../styles/hamburgerMenu.module.css";
import { Link } from 'react-router-dom';

const HamburgerMenu = ({t}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const handleToggleMenu = (event) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const handleCloseMenu = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsMenuOpen(false);
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleCloseMenu);
    
        return () => {
          document.removeEventListener('click', handleCloseMenu);
        };
      }, []);
    
      const handleMenuClick = (event) => {
        event.stopPropagation();
      };

    return (
        <div className={styles.hamburger} onClick={handleToggleMenu}>
            <div className={styles.hamburger_lines}>
              <div className={`${styles.line} ${styles.line1} ${isMenuOpen && styles.open}`}/>
              <div className={`${styles.line} ${styles.line2} ${isMenuOpen && styles.open}`}/>
              <div className={`${styles.line} ${styles.line3} ${isMenuOpen && styles.open}`}/>
            </div>
                <div ref={menuRef} onClick={handleMenuClick} className={styles.menu_content} style={isMenuOpen ? { transform: 'translate(0%)' } : {}}>
                    <Link className={styles.link_text} to="/products">{t('header.productlist')}</Link>
                    <Link className={styles.link_text} to="/feedback">{t('header.feedback')}</Link>
                </div> 
        </div>
    )
}

export { HamburgerMenu }