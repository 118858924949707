import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { FilePicker } from './filePicker';
import styles from "../styles/searchPage.module.css";
import { GraySpace } from "./graySpace";
import { Checkbox } from "@mui/material";
import { Link } from 'react-router-dom';
import * as ACTIVE_STATUS from '../constants/searchPageStatuses';
import ReactGA from 'react-ga4';

const SearchPage = ({ t, lng }) => {
    const [isActive, setActive] = useState(ACTIVE_STATUS.RULES);
    const [checked, setChecked] = useState(false);
    const filepickerRef = useRef();

    const handleCheckBoxChange = () => {
        setChecked(!checked);
    };

    const scrollToFilepicker = () => {
        const headerHeight = document.getElementById('header').offsetHeight;
        const filepickerElement = filepickerRef.current;

        if (filepickerElement) {
            const rect = filepickerElement.getBoundingClientRect();
            const offset = rect.top + window.scrollY - headerHeight;

            window.scrollTo({
                top: offset,
                behavior: 'smooth',
            });
        } else {
            console.error('scroll to element not found');
        }
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/find", title: "Страница поиска" });
    }, [])

    useEffect(() => {
        if (checked) {
            setActive(ACTIVE_STATUS.FILEPICKER);
            scrollToFilepicker();
        }
        else setActive(ACTIVE_STATUS.RULES);
    }, [checked])
    
    return (
        <div className={styles.wrapper}>
            <div className={styles.canvas_wrapper}>
                
                <div className={classNames(
                    styles.rules_wrapper,
                    isActive === ACTIVE_STATUS.RULES && styles.active
                )}>
                
                    <div className={styles.rules_canvas}>
                        <div className={styles.rules_container}>
                            <span className={styles.rules_title}>
                                {t('searchPage.before_search')}
                            </span>
                            <div className={styles.rules_checkbox_text_container}>
                                <Checkbox
                                    className={styles.no_padding} 
                                    checked={checked}
                                    onChange={handleCheckBoxChange}
                                />
                                <span className={styles.rules_text}>
                                    {t('searchPage.i_agree_with')} 
                                    {" "}
                                    <Link className={styles.offer_link} to="/offer">{t('searchPage.rules_of_service')}</Link>
                                    {" "}
                                    {t('searchPage.i_am_18+')} 
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {checked ?
                <div ref={filepickerRef}>
                <FilePicker
                    t={t}
                    lng={lng}
                    isActive={isActive}
                    setActive={setActive}
                />
                </div>:
                <GraySpace/>
                }
            </div>
        </div>
    );
}

export { SearchPage };