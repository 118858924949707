import React from 'react';
import styles from "../styles/offer.module.css";

const OfferPage = () => {
    return (
        <div className={styles.offer}>
            <b>Публичная Оферта</b>
            <p>Настоящий документ является официальным предложением индивидуального предпринимателя Лагойская Юлия Викторовна,<br/>
                 ОГРНИП: 323774600451565, ИНН: 772177656371, (далее по тексту – «Исполнитель»), в адрес лица, 
                 осуществившего акцепт оферты,<br/> на сайте <a href="https://fotosherlock.ru">fotosherlock.ru</a> пользователя сети Интернет, достигшего совершеннолетия (далее – «Заказчик»), 
                 заключить договор оказания услуг (далее – «Договор») на указанных ниже условиях (далее – «Оферта»).
            </p>

            <ol class={styles.custom_ol}>
                <li>Определение терминов  
                    <ol>
                        <li>Публичная оферта (далее – «Оферта») – настоящий документ, размещенный на сайте <a href="https://fotosherlock.ru">fotosherlock.ru</a>.</li>
                        <li>Заказчик – лицо, осуществившее акцепт оферты, подтвердившее достижение им совершеннолетия,  являющееся таким образом Заказчиком услуг Исполнителя по заключенному договору.</li>
                        <li>Стороны – Исполнитель и Заказчик при совместном упоминании. </li>
                        <li>«Акцепт» – полное и безоговорочное принятие Заказчиком условий Договора.</li>
                    </ol>
                </li>

                <li>Предмет Договора
                    <ol>
                        <li>Предметом настоящей Оферты является оказание Заказчику услуг (на возмездной основе) в соответствии с требованиями, предъявляемыми Заказчиком, условиями настоящей Оферты, и текущим Прайс-листом Исполнителя. </li>
                        <li>Полный перечень услуг приведен в приложении к настоящему договору.</li>
                        <li>Заказчик обязуется оплатить услуги Исполнителю в порядке и сроки, установленные настоящим Договором.</li>
                        <li>Акцептом считается осуществление следующих действий: полное и безоговорочное принятие Заказчиком условий настоящей Оферты, подтверждающее полное согласие Заказчика со всеми его пунктами и положениями и отсутствие каких-либо возражений в отношении Оферты или отдельных ее пунктов. </li>
                        <div className={styles.paragraph}>
                            <p>Акцепт оферты равносилен заключению договора возмездного оказания услуг на условиях, изложенных в оферте. Акцептом оферты является осуществление Заказчиком внесения стоимости услуг в соответствии с условиями настоящей оферты. С момента поступления денежных средств в счет оплаты оказываемых услуг на расчетный счет Исполнителя, настоящий договор считается заключенным между Заказчиком и Исполнителем. </p>
                            <p>Принятие настоящей Оферты означает, что Заказчик в необходимой для него степени ознакомился и согласен с условиями настоящего договора, а также с условиями Исполнителя.</p>
                            <p>При этом заключение Договора в простой письменной форме не является обязательным.</p>
                        </div>
                    </ol>
                </li>

                <li>Общие положения
                    <ol>
                        <li>Настоящий Договор считается заключенным с момента акцепта – совершения следующих действий: загрузки фотографии человека в форму на сайте <a href="https://fotosherlock.ru">fotosherlock.ru</a> и полной оплаты услуги через сервис «ЮMoney» («ЮМани»). </li>
                        <li>Оплата вносится в размере 100 % предоплаты. </li>
                        <li>Исполнитель имеет право вносить изменения в оферту в любой момент по своему усмотрению. Публикация данных изменений осуществляется за 10 календарных дней, до их вступления в силу.</li>
                        <li>Исполнитель предоставляет полную информацию об услугах, порядке и сроках исполнения услуг.</li>
                    </ol>
                </li>

                <li>Стоимость услуг и порядок расчетов
                    <ol>
                        <li>Стоимость возмездных услуг составляет 400 (четыреста) рублей.</li>
                        <li>Оплата производится безналичным путем через сервис «ЮMoney» («ЮМани»), либо наличными денежными средствами через банк по платежным реквизитам, направленным Исполнителем.</li>
                        <li>Датой оплаты считается дата поступления денежных средств на расчетный счет Исполнителя. </li>
                        <li>Оплата услуг по договору возмездного оказания услуг производится Заказчиком перечислением или путем перевода денежных средств Исполнителю в рамках применяемых форм безналичных расчетов в соответствии с пунктом 3 статьи 16.1 Закона от 07.02.1992 N 2300-1 "О защите прав потребителей" и Федеральным законом от 27.06.2011 N 161-ФЗ "О национальной платежной системе".</li>
                    </ol>
                </li>

                <li>Порядок оказания услуг
                    <ol>
                        <li>Услуги оказываются на основании представленных Заказчиком фотографий, подходящих по параметрам Исполнителя.</li>
                        <li>Заказчик вносит 100 % предоплату.</li>
                        <li>После оказания услуги, Исполнитель выводит Заказчику результаты со ссылками на интернет-ресурсы, содержащие похожие фотографии.</li>
                        <li>В течение всего срока действия настоящего договора, Заказчик может представлять любые фотографии неограниченное количество раз.</li>
                        <li>Заказчик может вернуть денежные средства в любой момент, пока не воспользуется услугами сервиса. После того, как Заказчик воспользовался услугами сервиса, денежные средства возврату во внесудебном порядке не подлежат.</li>
                        <li>После передачи Заказчику результатов оказанной услуги, Исполнитель не сохраняет полученную информацию.</li>
                    </ol>
                </li>

                <li>Права и обязанности
                    <ol>
                        <li>Исполнитель обязуется:</li>
                        <div className={styles.paragraph}>
                            <p>- производить поиск и подбор в соответствии с требованиями Заказчика, оказывать иные услуги, предусмотренные перечнем. </p>
                            <p>- предоставлять полную информацию об услугах, стоимости услуг, порядке и сроках исполнения.</p>
                        </div>
                        <li>Исполнитель имеет право: </li>
                        <div className={styles.paragraph}>
                            <p>- получать необходимую и достоверную информацию от Заказчика в целях исполнения настоящего Договора</p>
                            <p>- приостанавливать оказание услуг в одностороннем порядке в случае нарушений Заказчиком положений настоящего Договора</p>
                        </div>
                        <li>Заказчик обязуется: </li>
                        <div className={styles.paragraph}>
                            <p>- оплачивать услуги в соответствии с положениями Договора. </p>
                        </div>
                        <li>Заказчик имеет право: </li>
                        <div className={styles.paragraph}>
                            <p>- получать всю необходимую информацию и услуги в рамках настоящего Договора - получать консультации.</p>
                        </div>
                    </ol>
                </li>

                <li>Ответственность Сторон
                        <ol>
                            <li>За невыполнение или ненадлежащее выполнение обязательств по настоящей публичной оферте Стороны несут ответственность в соответствии с действующим законодательством Российской Федерации. </li>
                            <li>Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по Договору на время действия непреодолимой силы. Под непреодолимой силой понимаются чрезвычайные и непреодолимые при данных условиях обстоятельства, препятствующие исполнению своих обязательств Сторонами по настоящей Оферте. К ним относятся стихийные явления (землетрясения, наводнения и т. п.), обстоятельства общественной жизни (военные действия, чрезвычайные положения, крупнейшие забастовки, эпидемии и т. п.), запретительные меры государственных органов (запрещение перевозок, валютные ограничения, международные санкции запрета на торговлю и т. п.). В течение этого времени Стороны не имеют взаимных претензий, и каждая из Сторон принимает на себя свой риск последствия форс-мажорных обстоятельств. </li>
                            <li>Исполнитель не несет ответственности за использование полученных результатов кем-либо без согласия и ведома Заказчика.</li>
                            <li>Все разногласия и споры, которые могут возникнуть между Сторонами из настоящего Договора и в связи с ним, будут разрешаться путем ведения переговоров. В случае невозможности урегулирования разногласий путем ведения переговоров все споры между Сторонами разрешаются в судебном порядке.</li>
                        </ol>
                    </li>

                <li>Срок действия оферты
                    <ol>
                        <li>Настоящая Публичная оферта вступает в силу с момента ее акцепта Заказчиком, и действует до момента отзыва акцепта Публичной оферты. </li>
                    </ol>
                </li>

                <li>Реквизиты
                    <ol>
                        <li>Индивидуальный предприниматель Лагойская Юлия Викторовна</li>
                        <div className={styles.paragraph}>
                            <p>ИНН: 772177656371</p>
                            <p>ОГРНИП: 323774600451565</p>
                            <p>Наименование зарегистрировавшего его органа: Межрайонная инспекция Федеральной налоговой службы №46 по г. Москве</p>
                            <p>ОКВЭД: 63.11 Деятельность по обработке данных, предоставление услуг по размещению информации и связанная с этим деятельность</p>
                            <p>support@fotosherlock.ru</p>
                        </div>
                    </ol>
                </li>
            </ol>
            <br/>
            <p>Дата размещения оферты: 1.12.2023</p>
        </div>
    );
}

export { OfferPage };