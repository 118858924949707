import React, { useState, useEffect } from 'react';
import { TextField, Button, Modal, Typography } from '@mui/material';
import styles from "../styles/feedback.module.css";
import axios from "axios";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReactGA from 'react-ga4';

import { FEEDBACK_URL_PATH as FEEDBACK_PATH } from "../constants/pathConstants";

const FeedbackForm = ({ t }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState([]);
  const [isRequestMade, setRequestMade] = useState(false);
  const [open, setOpen] = useState(true);
  const maxLength = 600; //max symbols
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMobile, setIsMobile] = useState(window.screen.width <= 480);

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/feedback", title: "Обратная связь" });

      const handleResize = () => {
        setIsMobile(window.screen.width <= 480);
      };
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const emailText = isMobile
    ? t('feedback.email_mobile')
    : t('feedback.email');

// Проверка валидности формы при каждом изменении сообщения
useEffect(() => {
  if (message.length > maxLength || message.length === 0 || name.length === 0 || !emailPattern.test(email)) {
      setIsFormValid(false);
  } else {
      setIsFormValid(true);
  }
}, [message, name, email]);

  const handleSubmit = async (event) => {
    try{
      event.preventDefault();
      const data = JSON.stringify({
        name: name,
        email: email,
        message: message 
      });
      const res = await axios.request({
        url: `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${FEEDBACK_PATH}`, 
        method: "POST",
        data,
        headers: {
            'Content-Type': 'application/json'
        }
      })
      setResponse(res.data.status.ok);
      setRequestMade(true);
      setOpen(true);
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {console.log(error);}
  }

  const successFeedback = ( 
    <div className={styles.result_page}>
        <CheckCircleOutlineIcon className={styles.success_icon}/>
        <Typography>{t('feedback.succeeded')}</Typography>
    </div>
)

const errorFeedback = ( 
    <div className={styles.result_page}>
        <ErrorOutlineIcon className={styles.error_icon}/>
        <Typography>{t('feedback.smth_went_wrong')}</Typography>
        <Typography>{t('feedback.write_us_later')}</Typography>
    </div>
)

  return (
    <div>
      <form onSubmit={handleSubmit} className={styles.formStyle}>
          <div className={styles.box}>
              <TextField
                  className={styles.field_background}
                  type="text"
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  label={t('feedback.name')}
                  variant="outlined"
                  margin="normal"
                  required
              />
              <TextField
                  className={styles.field_background}
                  type="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label={emailText}
                  variant="outlined"
                  margin="normal"
                  error={email.length > 0 && !emailPattern.test(email)}
                  helperText={email.length > 0 && !emailPattern.test(email) ? t('feedback.email_wrong') : ""}
                  required
              />
              <TextField
                  className={styles.field_background}
                  name="message"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  label={t('feedback.message')}
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={6}
                  error={message.length > maxLength}
                  helperText={message.length > maxLength ? t('feedback.limit_exceeded') : ""}
                  required
              />
              <Button type="submit" variant="contained" color="primary" size="large" disabled={!isFormValid}>
              {t('feedback.submit')}
              </Button>
          </div>
      </form>
      {isRequestMade ?
        <Modal 
        open={open}
        onClose={() => {setRequestMade(false); setOpen(false);}}
        >
            <div className = {styles.modal}>
                {response ? successFeedback : errorFeedback}
                <button
                    onClick={() => {setRequestMade(false); setOpen(false)}}
                    className={styles.close_button}
                >
                {t('feedback.close')}
                </button>
            </div>   
        </Modal> : ""
        }
    </div>
  );
}

export { FeedbackForm };
