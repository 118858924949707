import './App.css';
import './global.css';
import './fonts/pt-sans.css';
import React, { useEffect } from "react";

import { HomePage } from './components/homePage';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { ProductList } from './components/products';
import { FeedbackForm } from './components/feedback';
import { OfferPage } from './components/offer';
import { Results } from './components/results';
import { NotFoundPage } from './components/notFoundPage';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledEngineProvider } from "@mui/system";
import { SearchPage } from './components/searchPage';
import { ScrollToTop } from './components/scrollToTop';

import { CouponProvider } from './components/couponProvider';
import ReactGA from 'react-ga4';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t('App.title');

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', t('App.description'));
    }

    initializeReactGA();
  }, [])

  const initializeReactGA = () => {
    if (process.env.REACT_APP_GA_TRACKING_CODE) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
    }
  }

  const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      document.title = t('App.title');
  }

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
        <ScrollToTop>
        <CouponProvider>
        <Header t={t} lng={i18n.language} changeLanguage={changeLanguage}/>
        <Routes>
          <Route path="/" element={<HomePage t={t} lng={i18n.language} changeLanguage={changeLanguage}/>}/>
          <Route path="/coupon/:couponCode" element={<HomePage t={t} lng={i18n.language} changeLanguage={changeLanguage}/>}/>
          <Route path="/find/" element={<SearchPage t={t} lng={i18n.language}/>}/>
          <Route path="/find/:localCoupon" element={<SearchPage t={t} lng={i18n.language}/>}/>
          <Route path="/products" element={<ProductList t={t}/>}/>
          <Route path="/feedback" element={<FeedbackForm t={t}/>}/>
          <Route path="/offer" element={<OfferPage/>}/>
          <Route path="/results/:requestGUID" element={<Results t={t}/>}/>
          <Route path="/results_util/:requestGUID" element={<Results t={t}/>}/>
          <Route path="*" element={<NotFoundPage t={t}/>}/>
        </Routes>
        <Footer t={t}/>
        </CouponProvider> 
        </ScrollToTop>
        </BrowserRouter>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
