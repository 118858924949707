import React, { useEffect, useState } from "react";
import styles from "../styles/users.module.css";
import axios from "axios";

import People from '../images/People.svg';
import MagnifierGlass from '../images/Magnifier_Glass.svg';

import { ACTIVITY_STATS_URL_PATH as ACTIVITY_STATS_PATH } from "../constants/pathConstants";

const UsersPage = ({ t }) => {
    const [totalUsersCount, setTotalUsersCount] = useState([]);
    const [recentlyUsersCount, setRecentlyUsersCount] = useState([]);
    const [isMobile, setIsMobile] = useState(window.screen.width <= 480);
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.screen.width <= 480);
        };
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const totalUsersCountText = isMobile
        ? t('users.total_count_mobile')
        : t('users.total_count');

    const getActivityStats = async () => {
        try {
            const res = await axios.request({
                url: `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${ACTIVITY_STATS_PATH}`,
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setTotalUsersCount(res.data.total_users);
            setRecentlyUsersCount(res.data.recently_users);
        } catch (error) {console.log(error);}
    }

    useEffect(() => {
        getActivityStats();
      }, []);

    return (
        <div className={styles.users}>
            <div className={styles.count_container}>
                <img
                    src={People} 
                    alt="People" 
                />
                <span className={styles.count_number}>{totalUsersCount}</span>
                <span className={styles.count_text}>{totalUsersCountText}</span>
            </div>
            <div className={styles.count_container}>
                <img
                    src={MagnifierGlass} 
                    alt="Magnifier glass" 
                />
                <span className={styles.count_number}>{recentlyUsersCount}</span>
                <span className={styles.count_text}>{t('users.recently_count')}</span>
            </div>
        </div>
    );
}

export { UsersPage };