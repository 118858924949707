import React, { useState, useEffect } from "react";
import styles from "../styles/coupon.module.css";
import axios from "axios";
import * as STATUS from '../constants/responseStatuses';

import Attention from "../images/Attention.svg"
import Done from "../images/DoneStroke.svg"

import { useCoupon } from './couponProvider';
import ReactGA from 'react-ga4';

const Coupon = ({ couponCode, couponURL, onCouponActive, setIsCouponLoading, t }) => {
    const [alert, setAlert] = useState(null);
    const [discount, setDiscount] = useState(0)
    const [isActive, setIsActive] = useState(true);
    const [error, setError] = useState(false);
    const { globalCoupon } = useCoupon();
    const { setGlobalCoupon } = useCoupon();

    useEffect(() => {
        if (couponCode) {
            handleCouponValidation();
        }
      }, [couponCode]);

      useEffect(() => {
        if (discount > 0) {
            onCouponActive(discount, isActive);
        }
      }, [discount]);

      useEffect(() => {
        if (couponCode && alert) {
            ReactGA.event({
                category: 'coupon',
                action: `${alert}`,
                label: `${couponCode}`,
            });
        }
      }, [alert])

    const handleCouponValidation = async () => {
        try {
            const res = await axios.request({
                url: couponURL, 
                method: "GET",
                params: {coupon_code: couponCode},
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setAlert(`coupon.${res.data.status}`);
            if (res.data.status !== 'success')
                setError(true);
            setDiscount(res.data.discount_amount);
            setIsCouponLoading(false);  
        } catch (error) {
            setError(true);
            setIsCouponLoading(false);  
            if (error.response.status === STATUS.NOT_FOUND_404) {
                setAlert('coupon.not_found')
                if (globalCoupon) {
                    setGlobalCoupon(null);
                }
            }
            else setAlert('universal_error')
        }
    }

    const titleStyleClass = `${styles.alert_title}
        ${alert === 'coupon.success' && styles.success}
        ${error && styles.error}`;

    const descStyleClass = `${styles.alert_desc}
        ${alert === 'coupon.success' && styles.success}
        ${error && styles.error}`;

    const alertDescText = error ? t('coupon.no_coupon_info') : t('coupon.auto_apply');

    const alertImg = error ? <img src={Attention}/> : <img src={Done}/>;

    return (
        <div className={`
            ${styles.coupon_container} 
            ${alert === 'coupon.success' && styles.success} 
            ${error && styles.error}
        `}>
            
            {alertImg}
            <span className={titleStyleClass}>
                {t(alert)}
            </span>
            <span className={descStyleClass}> 
                {alertDescText}
            </span>
        </div>
    )
}

export { Coupon };
