import React from 'react';
import styles from "../styles/notFoundPage.module.css";

const NotFoundPage = ({ t }) => {
    return (
        <div className={styles.error_container}>
            <div className={styles.error_code}>404</div>
            <div className={styles.error_message}>{t('notFoundPage.error')}</div>
        </div>
    );
}

export { NotFoundPage };