import React, { useEffect, useState } from "react";
import styles from "../styles/resultsUtility.module.css";

import axios from "axios";
import { COUPON_URL_PATH as COUPON_PATH } from "../constants/pathConstants";
import { FIND_FRONTEND_PATH as FIND_PATH } from "../constants/pathConstants";

import Repeat from '../images/Repeat.svg';
import { ReactComponent as Coupon } from '../images/Coupon.svg';
import Download from '../images/Download.svg';

import { Rating } from "./rating";
import ReactGA from 'react-ga4';

const ResultsUtility = ({t, requestGUID}) => {
    const couponURL = `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${COUPON_PATH}`
    const newSearchURL = `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${FIND_PATH}`
    
    const couponDiscount = `${process.env.REACT_APP_COUPON_FOR_REPEAT_DISCOUNT}`

    const [isMobile, setIsMobile] = useState(window.screen.width <= 480);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.screen.width <= 480);
      };
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const sendGA = (action) => {
        ReactGA.event({
          category: 'button',
          action: `${action}`,
          label: `${requestGUID}`
        });
    };

    const getNewCoupon = async (req_guid) => {
        try {
            let data_fields = {
                request_guid: req_guid,
                reason: 'repeat_payment'
            }
            const data = JSON.stringify(data_fields);

            const res = await axios.request({
                url: couponURL, 
                method: "POST",
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (res.data) {
                return (res.data.coupon_code);
            }
        } catch (error) {
                console.log(error);
        }
    }

    const handleNewSearchClick = async () => {
        const coupon_code = await getNewCoupon(requestGUID);
        if (coupon_code) {
            const url = `${newSearchURL}/${coupon_code}`;
            if (isMobile) {
                window.location.href=url;
            }
            else {
                window.open(url, '_blank');
            }
        }
    };
    
    return (      
        <div className={styles.side_func_container}>

            {/* Rating */}
            <Rating t={t} requestGUID={requestGUID} isMobile={isMobile}/>

            <div className={styles.arrange_to_row}>
                {/* newSearch */}
                <div className={styles.side_func_wrapper}>
                    {isMobile ?
                        null
                    :
                        <span className={styles.side_func_info}>
                            {t('results.coupon_new_search_info')}
                        </span>
                    }
                    <div className={styles.button_wrapper}>
                        <button className={`
                                ${styles.side_func_button}
                                ${isMobile && styles.new_search_button}
                            `}
                            onClick={() => {sendGA('discount_search'); handleNewSearchClick()}}
                        >
                            {isMobile ? 
                                <div className={styles.image_container}>
                                    <Coupon className={styles.coupon_position}/>
                                    <span className={styles.image_text}>{`-${couponDiscount}%`}</span>
                                </div>
                            : 
                                <img src={Repeat} alt="Repeat"/>
                            }
                            <span className={styles.side_func_button_text}>
                                {t('results.new_search_button')}
                            </span>
                            {isMobile ?
                                null
                            :
                                <div className={styles.image_container}>
                                    <Coupon className={styles.coupon_position}/>
                                    <span className={styles.image_text}>{`-${couponDiscount}%`}</span>
                                </div>
                            }
                        </button>
                    </div>
                </div>

                {/* to PDF */}
                {/* <div className={styles.side_func_wrapper}>
                    <span>template</span> 
                </div> */}
            </div>
        </div>
    )
}

export { ResultsUtility };