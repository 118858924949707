import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PRODUCTS_URL_PATH as PRODUCTS_PATH } from "../constants/pathConstants";
import styles from "../styles/products.module.css";

const ProductList = ({ t }) => {
    const [products, setProducts] = useState([]);

    async function getProducts() {
        const productsURL = `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${PRODUCTS_PATH}`;
        const res = await axios.request({
            url: productsURL, 
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        })  
        setProducts(res.data);
    }

    useEffect(() => {
        try {
            getProducts();
        }
        catch (error) {console.log(error)};
    }, []);

    return (
        <div className={styles.products}>
        <table>
        <thead className={styles.thead}>
            <tr align="center" className={styles.tr}>
                <th>№</th>
                <th>{t('products.service_name')}</th>
                <th>{t('products.price')}</th>
            </tr>
        </thead>
        <tbody>
            {products.map((product, index) => {
                return(
                    <tr key={index}>
                        <td align="left">{++index}</td>
                        <td align="left">
                            {product.description}
                        </td>
                        <td align="left">
                            {product.price} {t('products.rubles')}
                        </td>
                    </tr>
                )
            })}
        </tbody>
        </table>
        </div>
    );
};

export { ProductList };