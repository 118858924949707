import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import styles from "../styles/carousel.module.css";

const Carousel = ({ items, autoPlayInterval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(Math.floor(items.length / 2));

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe(1),
    onSwipedRight: () => handleSwipe(-1),
    preventDefaultTouchmoveEvent: true,
  });

  const handleSwipe = (delta) => {
    const newIndex = (currentIndex + delta + items.length) % items.length;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIndex = (currentIndex + 1) % items.length;
      setCurrentIndex(newIndex);
    }, autoPlayInterval);

    return () => clearInterval(intervalId);
  }, [currentIndex, items.length, autoPlayInterval]);

return (
    <div
      {...handlers}
      className={styles.swiper_wrapper}
    >
      <div
        className={styles.swiper}
        style={{transform: `translateX(-${currentIndex * 100}%)`}}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={styles.item_wrapper}
          >
            {item}
          </div>
        ))}
      </div>
      <div className={styles.pagination_wrapper}>
        {items.map((_, index) => (
          <div
            key={index}
            className={`${styles.pagination_element} ${
                index === currentIndex && styles.active
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export { Carousel };