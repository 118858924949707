// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel_swiper_wrapper__6xmYs {
    overflow: hidden;
    width: 100%;
    position: relative;
}

.carousel_swiper__XzPV4 {
    display: flex;
    transition: transform 0.3s ease-in-out 0s;
}

.carousel_item_wrapper__Og0o0 {
    flex: 0 0 100%;
    max-width: 100%;
    box-sizing: border-box;
}

.carousel_pagination_wrapper__Mscn6 {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    left: 130px;
}

.carousel_pagination_element__9p5Gj {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(170, 170, 170);
    margin: 0px 5px;
    cursor: pointer;

    &.carousel_active__EAynI {
        background-color: #333;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/carousel.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,yCAAyC;AAC7C;;AAEA;IACI,cAAc;IACd,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,oCAAoC;IACpC,eAAe;IACf,eAAe;;IAEf;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".swiper_wrapper {\r\n    overflow: hidden;\r\n    width: 100%;\r\n    position: relative;\r\n}\r\n\r\n.swiper {\r\n    display: flex;\r\n    transition: transform 0.3s ease-in-out 0s;\r\n}\r\n\r\n.item_wrapper {\r\n    flex: 0 0 100%;\r\n    max-width: 100%;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.pagination_wrapper {\r\n    display: flex;\r\n    justify-content: center;\r\n    position: absolute;\r\n    bottom: 10px;\r\n    left: 130px;\r\n}\r\n\r\n.pagination_element {\r\n    width: 10px;\r\n    height: 10px;\r\n    border-radius: 50%;\r\n    background-color: rgb(170, 170, 170);\r\n    margin: 0px 5px;\r\n    cursor: pointer;\r\n\r\n    &.active {\r\n        background-color: #333;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper_wrapper": `carousel_swiper_wrapper__6xmYs`,
	"swiper": `carousel_swiper__XzPV4`,
	"item_wrapper": `carousel_item_wrapper__Og0o0`,
	"pagination_wrapper": `carousel_pagination_wrapper__Mscn6`,
	"pagination_element": `carousel_pagination_element__9p5Gj`,
	"active": `carousel_active__EAynI`
};
export default ___CSS_LOADER_EXPORT___;
