// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 481px) {
  .recognitionProgress_progress_wrapper__CYpCd {
    height: 120px;
  }

  .recognitionProgress_text_wrapper__H-vN0 {
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .recognitionProgress_progress_bar__PXkhH {
  height: 15px;
  }

  .recognitionProgress_text__x7TA3 {
    font-family: "PT Sans", Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

@media (max-width: 480px) {
  .recognitionProgress_progress_wrapper__CYpCd {
    height: 88px;
  }

  .recognitionProgress_text_wrapper__H-vN0 {
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .recognitionProgress_progress_bar__PXkhH {
  height: 10px;
  }

  .recognitionProgress_text__x7TA3 {
    font-family: "PT Sans", Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/recognitionProgress.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;;EAEA;IACE,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;EACA,YAAY;EACZ;;EAEA;IACE,iCAAiC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;EACA,YAAY;EACZ;;EAEA;IACE,iCAAiC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;EACnB;AACF","sourcesContent":["@media (min-width: 481px) {\r\n  .progress_wrapper {\r\n    height: 120px;\r\n  }\r\n\r\n  .text_wrapper {\r\n    padding-bottom: 15px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n\r\n  .progress_bar {\r\n  height: 15px;\r\n  }\r\n\r\n  .text {\r\n    font-family: \"PT Sans\", Helvetica;\r\n    font-size: 24px;\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    line-height: 140%;\r\n  }\r\n}\r\n\r\n@media (max-width: 480px) {\r\n  .progress_wrapper {\r\n    height: 88px;\r\n  }\r\n\r\n  .text_wrapper {\r\n    padding-bottom: 15px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n\r\n  .progress_bar {\r\n  height: 10px;\r\n  }\r\n\r\n  .text {\r\n    font-family: \"PT Sans\", Helvetica;\r\n    font-size: 18px;\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    line-height: 140%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress_wrapper": `recognitionProgress_progress_wrapper__CYpCd`,
	"text_wrapper": `recognitionProgress_text_wrapper__H-vN0`,
	"progress_bar": `recognitionProgress_progress_bar__PXkhH`,
	"text": `recognitionProgress_text__x7TA3`
};
export default ___CSS_LOADER_EXPORT___;
