import React, { useEffect, useState } from "react";
import styles from "../styles/results.module.css";
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';

import axios from "axios";
import { RESULTS_URL_PATH as RESULTS_PATH } from "../constants/pathConstants";
import { REQUEST_URL_PATH as REQUEST_PATH } from "../constants/pathConstants";
import { ResultsCard } from "./resultsCard";
import { ResultsUtility } from "./resultsUtility";
import { RecognitionProgress } from "./recognitionProgress";
import { Modal } from "@mui/material";

import * as REQ_STATUS from '../constants/requestStatuses';

import High_acc from '../images/Bullseye.png';
import Mid_acc from '../images/Thumbs_Up.png';
import Low_acc from '../images/Thinking_Face.png';
import { Alert } from "./alert";
import ReactGA from 'react-ga4';

const Results = (props) => { 
    const { requestGUID } = useParams();
    const navigate = useNavigate();
    const resultsURL = `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${RESULTS_PATH}`
    const requestURL = `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${REQUEST_PATH}`

    const raw_accuracy = process.env.REACT_APP_REC_ACCURACIES;
    const accuraciesArray = raw_accuracy.split(',').map(item => parseFloat(item.trim()));
    accuraciesArray.sort((a, b) => b - a);
    const [groupedData, setGroupedData] = useState([]);
    const [resultsLoaded, setResultsLoaded] = useState(false);
    const [isDataBig, setIsDataBig] = useState(false);
    const [status, setStatus] = useState('');
    const [open, setOpen] = useState(true);

    const [progress, setProgress] = useState(0);
    const [progressEnd, setProgressEnd] = useState(true); 
    const [progressInitiated, setProgressInitiated] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            const currentPath = location.pathname;

            if (currentPath.startsWith('/results/')) {
                handleDefaultResultsInit();
            } else if (currentPath.startsWith('/results_util')) {
                await getResults();
                setResultsLoaded(true);
            }
        }

        fetchData();
    }, [location.pathname]);

    const handleDefaultResultsInit = () => {
        ReactGA.send({ hitType: "pageview", page: "/results", title: "Страница результатов" });

        // Initiate progress bar
        if (!progressInitiated) {
            setProgress(10);
            setProgressEnd(false);
            setProgressInitiated(true);
        }

        if (requestGUID) {
            handleWaitCompletedRequest();
        }
    }

    useEffect(() => {
        if (status === REQ_STATUS.COMPLETED) {
            sendGA('result_success');
            getResults();
            setProgressInitiated(true);
        }
        if (status === REQ_STATUS.NOT_PAID || status === REQ_STATUS.ERROR || status === REQ_STATUS.CANCELLED) {
            setResultsLoaded(true);
            setProgressEnd(true);
        }
    }, [status])

    useEffect(() => {
        if (progressEnd && progressInitiated) {
            setResultsLoaded(true);
        }
    }, [progressEnd, progressInitiated])

    const sendGA = (action) => {
        ReactGA.event({
          category: 'result',
          action: `${action}`,
          label: `${requestGUID}`
        });
    };

    const getStatus = async () => {
        try {
            const res = await axios.request({
                url: requestURL, 
                method: "GET",
                params: {request_guid: requestGUID},
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return res.data.status;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return Promise.reject(new Error(error.response.status));
            }
            else 
                return REQ_STATUS.ERROR;
        }
    }

    const handleWaitCompletedRequest = async () => {
        return await new Promise(async (resolve, reject) => {
            try { 
                setProgress(50);

                const req_status = await getStatus();

                if (req_status === REQ_STATUS.COMPLETED) {
                    setStatus(req_status);
                    setProgress(80);
                    return resolve();
                }
        
                if (req_status === REQ_STATUS.NOT_PAID || req_status === REQ_STATUS.ERROR || req_status === REQ_STATUS.CANCELLED) {
                    setStatus(req_status);
                    // TODO: switch to production build and use code below:
                    // return reject(new Error("Backend error occurred or YooKassa timeout"));
                    return resolve();
                }

                setTimeout(() => {handleWaitCompletedRequest().then(resolve).catch(reject)}, 1500);
                return;
            } catch (error) {
                console.log(error);
                if (error.message === "404") {
                    navigate(`/404`);
                }
                setStatus(REQ_STATUS.ERROR);
                // TODO: switch to production build and use code below:
                // return reject(error);
                return resolve(error);
            }
        });   
    };

    const getResults = async () => {
        try {         
            const res = await axios.request({
                url: resultsURL, 
                method: "GET",
                params: {request_guid: requestGUID},
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (res.data.length > 0) {
                const group = [];
                for (let i = 0; i < accuraciesArray.length; i++) {
                    const upperBound = i === 0 ? 1 : accuraciesArray[i-1];
                    const lowerBound = i === 0 ? accuraciesArray[0] : accuraciesArray[i];
                    group[i] = res.data.filter(item => item.accuracy > lowerBound && item.accuracy <= upperBound);
                }

                let totalLength = 0;
                for (let i = 0; i < group.length; i++) {
                    totalLength += group[i].length;
                
                    if (totalLength >= 10) {
                        setIsDataBig(true);
                        break;
                    }
                }
                setGroupedData(group);
            }
            setProgress(100);
        } catch (error) {
            console.log(error);
        }
    }

    const getAccuracySpanByOrder = (index) => {
        let accuracyText;
        switch (index) {
          case 0:
            accuracyText =
            <>
                <img className={styles.emoji} src={High_acc} alt="Bullseye"/>
                {props.t('results.best_accuracy')}
            </>
            break;
          case 1:
            accuracyText = 
            <>
                <img className={styles.emoji} src={Mid_acc} alt="Thumbs_Up"/>
                {props.t('results.mid_accuracy')}
            </>
            break;
          case 2:
            accuracyText = 
            <>
                <img className={styles.emoji} src={Low_acc} alt="Thinking_Face"/>
                {props.t('results.low_accuracy')}
            </>
            break;
          default:
            accuracyText = "";
        }

        return accuracyText
    }

    return (
        <div className={styles.results_page}>
            <div className={styles.results_width}>
                {resultsLoaded &&
                    <>
                    <ResultsUtility
                        requestGUID = {requestGUID}
                        t = {props.t} 
                    />
                    {groupedData.length > 0 ? 
                    <>
                        {groupedData.slice(0, -1).every(arr => arr.length === 0) && groupedData[groupedData.length - 1].length > 0 &&
                            <Alert
                                status={'info'}
                                titleText={props.t('results.no_good_results_found_title')}
                                descTextArray={[props.t('results.no_good_results_found_description')]}
                            />
                        }
                        <div className={styles.results_container}>
                            {groupedData.map((result_row, index) => (
                            result_row.length > 0 ? (
                                <div key={index} className={styles.accuracy_block_wrapper}>
                                    <div className={styles.header}>
                                        <span className={styles.accuracy_text}>
                                            {getAccuracySpanByOrder(index)}
                                            {" "}
                                            ({result_row.length})
                                        </span>
                                    </div>           
                                    <div key={result_row.accuracy} className={styles.card_container}>
                                    {result_row.map((element, elementIndex) => (
                                        <>
                                        <ResultsCard key={elementIndex} t={props.t} resultGUID={element.results}/>
                                        {index === groupedData.length - 1 && elementIndex === result_row.length - 1 && (
                                            <div className={styles.lighthouse}/>
                                        )}
                                        </>
                                    ))}
                                    </div>
                                </div>
                            ) : null
                            ))}
                        </div>
                    </>
                    : 
                        <>
                        {status === REQ_STATUS.COMPLETED &&
                            <Alert
                                status={'info'}
                                titleText={props.t('results.not_found_title')}
                                descTextArray={[
                                    props.t('results.not_found_description'),
                                    <br/>,<br/>,
                                    props.t('results.not_found_issue'),
                                    " ",
                                    <Link className={styles.feedback_link} to="/feedback">{props.t('error.feedback')}</Link>,
                                    ", ",
                                    props.t('error.other_ways_to_contact'),
                                    ": ",
                                    <a className={styles.feedback_link} href={`mailto:${props.t('footer.email')}`}>
                                        {props.t('footer.email')}
                                    </a>,
                                    ".",<br/>,
                                    props.t('results.we_offer_discount'),
                                    
                                ]}
                            />
                        }

                        {(status === REQ_STATUS.ERROR || status === REQ_STATUS.NOT_PAID || status === REQ_STATUS.CANCELLED) &&
                            <Alert
                                status={'error'}
                                titleText={props.t(`results.${status}_title`)}
                                descTextArray={[
                                    props.t(`results.${status}_description`),
                                    <br/>,
                                    props.t(`results.${status}_no_way_out`),
                                    " ",
                                    <Link className={styles.feedback_link} to="/feedback">{props.t('error.feedback')}</Link>,
                                    ", ",
                                    props.t('error.other_ways_to_contact'),
                                    ": ",
                                    <a className={styles.feedback_link} href={`mailto:${props.t('footer.email')}`}>
                                        {props.t('footer.email')}
                                    </a>
                                ]}
                            />
                        }
                        </>
                    } 
                    
                    {isDataBig &&
                        <ResultsUtility
                            requestGUID = {requestGUID}
                            t = {props.t} 
                        />  
                    }
                    </>
                }

                {progressEnd === false &&
                <>
                    <Modal 
                        open={open}
                    >
                        <div className={styles.modal}>
                            <RecognitionProgress 
                                t={props.t} 
                                progress={progress} 
                                setProgressEnd={setProgressEnd}
                            />
                        </div>
                    </Modal>
                </>
                }
                
            </div>
        </div>
    )
}

export { Results };