// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --black: rgba(17, 17, 17, 1);
  --blue: rgba(33, 127, 230, 1);
  --darkgrey: rgba(57, 57, 57, 1);
  --green: rgba(69, 163, 64, 1);
  --darkgreen: #2C4E2A;
  --red: #C5473D;
  --red25: rgba(197, 71, 61, 0.25);
  --darkred: #410303;
  --yellow: #F1B300;
  --grey: rgba(121, 121, 121, 1);
  --white: rgb(255, 255, 255, 1);
  --whitegrey: rgba(240, 240, 240, 1);
  --brown: #A36440;
  --yellow25: rgba(163, 141, 64, 0.25); 
  --gradient-rainbow: linear-gradient(90deg, rgba(121, 121, 121, 0.15) 0%, rgba(255, 47, 47, 0.15) 33.33%, rgba(69, 163, 64, 0.15) 66.67%, rgba(33, 127, 230, 0.15) 100%);
  --gradient-blue-bg: linear-gradient(83deg, rgba(33, 127, 230, 0.00) 0%, rgba(33, 127, 230, 0.33) 84.36%, rgba(33, 127, 230, 0.00) 100%);
  --gradient-green-bg: linear-gradient(83deg, rgba(96, 230, 33, 0.00) 0%, rgba(96, 230, 33, 0.33) 84.36%, rgba(96, 230, 33, 0.00) 100%);
  --gradient-placeholder-grey: linear-gradient(180deg, #E1E1E1 0%, rgba(225, 225, 225, 0.00) 100%);
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,6BAA6B;EAC7B,+BAA+B;EAC/B,6BAA6B;EAC7B,oBAAoB;EACpB,cAAc;EACd,gCAAgC;EAChC,kBAAkB;EAClB,iBAAiB;EACjB,8BAA8B;EAC9B,8BAA8B;EAC9B,mCAAmC;EACnC,gBAAgB;EAChB,oCAAoC;EACpC,uKAAuK;EACvK,uIAAuI;EACvI,qIAAqI;EACrI,gGAAgG;AAClG","sourcesContent":[":root {\r\n  --black: rgba(17, 17, 17, 1);\r\n  --blue: rgba(33, 127, 230, 1);\r\n  --darkgrey: rgba(57, 57, 57, 1);\r\n  --green: rgba(69, 163, 64, 1);\r\n  --darkgreen: #2C4E2A;\r\n  --red: #C5473D;\r\n  --red25: rgba(197, 71, 61, 0.25);\r\n  --darkred: #410303;\r\n  --yellow: #F1B300;\r\n  --grey: rgba(121, 121, 121, 1);\r\n  --white: rgb(255, 255, 255, 1);\r\n  --whitegrey: rgba(240, 240, 240, 1);\r\n  --brown: #A36440;\r\n  --yellow25: rgba(163, 141, 64, 0.25); \r\n  --gradient-rainbow: linear-gradient(90deg, rgba(121, 121, 121, 0.15) 0%, rgba(255, 47, 47, 0.15) 33.33%, rgba(69, 163, 64, 0.15) 66.67%, rgba(33, 127, 230, 0.15) 100%);\r\n  --gradient-blue-bg: linear-gradient(83deg, rgba(33, 127, 230, 0.00) 0%, rgba(33, 127, 230, 0.33) 84.36%, rgba(33, 127, 230, 0.00) 100%);\r\n  --gradient-green-bg: linear-gradient(83deg, rgba(96, 230, 33, 0.00) 0%, rgba(96, 230, 33, 0.33) 84.36%, rgba(96, 230, 33, 0.00) 100%);\r\n  --gradient-placeholder-grey: linear-gradient(180deg, #E1E1E1 0%, rgba(225, 225, 225, 0.00) 100%);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
