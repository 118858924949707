import { array, func } from "prop-types";
import React, { useState } from "react";
import styles from "../styles/dropZone.module.css";
import classNames from "classnames";
import Upload from '../images/Upload.svg';

import * as FACECHECK_STATUS from '../constants/filePickerStatuses';

const Banner = ({ onClick, onDrop, t }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const MAX_FILE_SIZE_MB = `${process.env.REACT_APP_MAX_BODY_SIZE}`;

  const handleDragOver = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = "copy";
    setIsDraggingOver(true);
  };

  const handleDragLeave = (ev) => {
    setIsDraggingOver(false);
  };

  const handleDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDrop(ev.dataTransfer.files);
    setIsDraggingOver(false);
  };

  return (
    <div
      className={classNames(
        styles.banner,
        isDraggingOver ? 
          styles.banner_drag_over : "")}
      onClick={onClick}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className={styles.top_wrapper}>
          <img
            src={Upload}
            alt="Upload"
          />
          <div className={styles.text_wrapper}>
            <span className={styles.banner_text}>{t('dropZone.drop')}</span>
            <span className={styles.banner_text}>{t('dropZone.or_load')}</span>
          </div>
      </div>
      <div className={styles.horizontal_line}/>
      <div className={styles.bottom_wrapper}>
          <span className={styles.requirements_text}>{t('dropZone.photo_req_detailed')}</span>
          <span className={styles.requirements_text}>{t('dropZone.allowed_format')}</span>
          <span className={styles.requirements_text}>{`${t('dropZone.allowed_size')} ${MAX_FILE_SIZE_MB} ${t('dropZone.MB')}`}</span>
      </div>
    </div>
  );
};

const DropZone = ({ onChange, setAlert, accept = ["image/*"], setStatus, t }) => {
  const inputRef = React.useRef(null);
  const MAX_FILE_SIZE_MB = `${process.env.REACT_APP_MAX_BODY_SIZE}`;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleClick = () => {
    inputRef.current.click();
  };

  const validateImageFiles = (files) => { 
    setAlert(false);
    const imageFiles = [...files].filter(file => {
      if (!file.type.startsWith('image/')) {
        setAlert('dropZone.file_type_error');
        setStatus(FACECHECK_STATUS.FAIL);
        return false;
      }      
      if (file.size > MAX_FILE_SIZE_BYTES) { 
        setAlert('dropZone.file_size_error');
        setStatus(FACECHECK_STATUS.FAIL);
        return false;
      }
      return true;
    });
    if (imageFiles.length > 0) {
      onChange(imageFiles);
    }
  };

  const handleChange = (ev) => {
    validateImageFiles(ev.target.files);
    inputRef.current.value = '';
  };

  const handleDrop = (files) => {
    validateImageFiles(files);
  };

  return (
      <div className={styles.wrapper}>
        <Banner onClick={handleClick} onDrop={handleDrop} t={t} />
        <input
          type="file"
          aria-label="add files"
          className={styles.input}
          ref={inputRef}
          onChange={handleChange}
          accept={accept.join(",")}
        />
      </div>
  );
};

DropZone.propTypes = {
  accept: array,
  onChange: func,
};

export { DropZone };