import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { useCallback, useState, useEffect, useRef } from "react";
import { DropZone } from "./dropZone";
import styles from "../styles/filePicker.module.css";
import { DataProcessor } from "./dataProcessor";
import { Editor } from "./editor";
import axios from "axios";

import { useParams } from 'react-router-dom';
import {ReactComponent as Dots} from "../images/Dots.svg"
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';

import { FACECHECK_URL_PATH as FACECHECK_PATH } from "../constants/pathConstants";
import { CREATE_PAYMENT_URL_PATH as PAYMENT_PATH } from "../constants/pathConstants";
import { PRODUCT_URL_PATH as PRODUCT_PATH } from "../constants/pathConstants";
import { COUPON_URL_PATH as COUPON_PATH } from "../constants/pathConstants";

import * as ACTIVE_STATUS from '../constants/searchPageStatuses';
import * as FACECHECK_STATUS from '../constants/filePickerStatuses';

import { GraySpace } from "./graySpace";
import { useCoupon } from './couponProvider';
import ReactGA from 'react-ga4';

const FilePicker = ({ t, lng, isActive=ACTIVE_STATUS.FILEPICKER, setActive }) => {
  const [files, setFiles] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [pressAllowed, setPressAllowed] = useState(true);
  const [alert, setAlert] = useState(null); 
  const [faceCheckProgress, setFaceCheckProgress] = useState(FACECHECK_STATUS.WAITING)
  const dataprocessorRef = useRef();
  const { localCoupon } = useParams();
  const { globalCoupon } = useCoupon();
  const [couponCode, setCouponCode] = useState('');

  const [acceptBtnLocale, setAcceptBtnLocale] = useState('filePicker.waiting');
  const dynamicAcceptBtnLocale = t(acceptBtnLocale);

  const [requestGUID, setRequestGUID] = useState('');

  const faceErrorMap = {
    "FaceNotFoundError": "filePicker.no_faces_error",
    "MultipleFacesError": "filePicker.more_than_one_face_error",
  };

  useEffect(() => {
      setCouponCode(localCoupon || globalCoupon || '');
  }, []);

  const sendGA = (label, alert) => {
    ReactGA.event({
      category: 'button',
      action: `${label}`,
      label: alert
    });
  };

  const scrollToDataprocessor = () => {
    const headerHeight = document.getElementById('header').offsetHeight;
    const dataprocessorElement = dataprocessorRef.current;

    if (dataprocessorElement) {
        const rect = dataprocessorElement.getBoundingClientRect();
        const offset = rect.top + window.scrollY - headerHeight;

        window.scrollTo({
            top: offset,
            behavior: 'smooth',
        });
    } else {
        console.error('scroll to element not found');
    }
  };

  const processFiles = (files) => {
    let filesArray = Array.from(files);
  
    while (filesArray.length > 1) {
      filesArray.pop();
    }
    return filesArray.map((file) => ({
      id: nanoid(),
      file
    }));
  };
  
  // handler called when files are selected via the Dropzone component
  const handleOnChange = useCallback((files) => {
    const processedFiles = processFiles(files);
  
    setFiles(processedFiles);
    setAlert(null);
    setFaceCheckProgress(FACECHECK_STATUS.WAITING);
  }, []);

  // function for editor component, rotation without
  const handleRotate = useCallback((files) => {
    const processedFiles = processFiles(files);
  
    setFiles(processedFiles);
  }, []);

  // handle for removing files form the files list view
  const handleClearFile = useCallback(() => {
    setFiles([]);

    // reset all statuses
    setOpen(false);
    setAlert(null);
    setPressAllowed(true);
    setFaceCheckProgress(FACECHECK_STATUS.WAITING);
    setActive(ACTIVE_STATUS.FILEPICKER);
  }, []);

  const handleCheckFace = async () => {
    try {
        const data = new FormData();
        for (const file of files) {
          data.append("img", file.file);
        }

        const res = await axios.request({
            url: `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${FACECHECK_PATH}`,
            method: "POST",
            data
        });

        setAlert('');
        setFaceCheckProgress(FACECHECK_STATUS.OK);
        setRequestGUID(res.data.request_guid);
    } catch (error) {
          console.log(error);
          setAlert(faceErrorMap[error.response.data] || "universal_error");
          setFaceCheckProgress(FACECHECK_STATUS.FAIL);
    }
  };

  const handlePressUpload = async () => {
    setPressAllowed(false);
    setFaceCheckProgress(FACECHECK_STATUS.SAVE_IMG);
  }
  
  const showCheckFaceProgress = () => {
    switch (faceCheckProgress) {
      case FACECHECK_STATUS.WAITING:
        return <Dots className={styles.status}/>;
      case FACECHECK_STATUS.SAVE_IMG:
        return <CircularProgress size="24px"/>;
      case FACECHECK_STATUS.START:
        return <CircularProgress size="24px"/>;
      case FACECHECK_STATUS.OK:
        return <DoneIcon className={styles.done}/>;
      case FACECHECK_STATUS.FAIL:
        return <ErrorIcon className={styles.error}/>;
      default:
        return null;
    }
  };

  useEffect(() => {
      setAcceptBtnLocale(`filePicker.${faceCheckProgress}`)
      if (faceCheckProgress === FACECHECK_STATUS.START) {
        handleCheckFace();
      }
      if (faceCheckProgress === FACECHECK_STATUS.OK) {
        setActive(ACTIVE_STATUS.DATAPROCESSOR);
      }
  }, [faceCheckProgress]);

  useEffect(() => {
    if (isOpen)
      scrollToDataprocessor();
  }, [isOpen])

  useEffect(() => {
    if (alert === '') 
      setOpen(true);
    if (alert)
      setAcceptBtnLocale(`${alert}`)
  }, [alert]);

  // Google Analytics
  useEffect(() => {
    if (faceCheckProgress===FACECHECK_STATUS.START || 
        faceCheckProgress===FACECHECK_STATUS.OK || 
        faceCheckProgress===FACECHECK_STATUS.FAIL) {
          sendGA(`face_${faceCheckProgress}`, alert);
        }
  }, [faceCheckProgress])
 
  return (
    <div>
      <div className={classNames(
          styles.wrapper,
          isActive === ACTIVE_STATUS.FILEPICKER && styles.active
        )}>
        <div className={styles.canvas_wrapper}>
          <div className={styles.container}>
            {files.length ?
              <Editor
                onClear={handleClearFile}
                onChange={handleRotate} 
                status={faceCheckProgress}
                setStatus={setFaceCheckProgress}
                files={files}
                isActive={isActive}
              />
            :
              <DropZone 
                onChange={handleOnChange} 
                t={t} 
                setStatus={setFaceCheckProgress}
                setAlert={setAlert}/>
            }

            <button 
              onClick={handlePressUpload}
              className={`${styles.accept_btn} 
              ${pressAllowed && files.length > 0 ? 
                styles.active : styles.disabled}`
              }
            >
              <div className={styles.text_wrapper}>{dynamicAcceptBtnLocale}</div>
                {faceCheckProgress && showCheckFaceProgress()}
            </button>
          </div>
        </div>
      </div>

        { isOpen ?
          (<div ref={dataprocessorRef}>
          <DataProcessor
            t = { t }
            lng = { lng }
            files = { files }
            requestGUID={requestGUID}
            couponCode={couponCode}
            paymentURL={`${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${PAYMENT_PATH}`}
            couponURL={`${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${COUPON_PATH}`}
            productURL={`${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${PRODUCT_PATH}`}
          />
          </div>):
          <GraySpace/>
        }
    </div>
  );
};

export { FilePicker };