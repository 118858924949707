import React, { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";

import styles from "../styles/recognitionProgress.module.css";

const RecognitionProgress = ({ t, progress, setProgressEnd }) => {
    const [currentProgress, setCurrentProgress] = useState(0);

    useEffect(() => {
      const timeInterval = 250; // time in ms how often progress bar updates
      const iterations = 1000 / timeInterval * 10; // number of iterations for ~10s 

      const interval = setInterval(() => {
        const diff = Math.abs(progress - currentProgress);

        let speed;
        if (progress === 100)
          speed = 8; 
          // for speed = 8:
          // max time from 0 to 100 is: 100/((1000/timeInterval)*8)=~3s. 
          // Balance between smoothness and speed
        else 
          speed = diff/iterations/2;

        if (currentProgress < 100) 
          setCurrentProgress(currentProgress + speed);
        else {
          clearInterval(interval);
          setProgressEnd(true);
        }
    }, timeInterval);

    return () => clearInterval(interval);
  }, [progress, currentProgress]);

    return (
      <div className={styles.progress_wrapper}>
        <div className={styles.text_wrapper}>
          <span className={styles.text}>{t('recognitionProgress.search_start')}</span> 
          <span className={styles.text}>{t('recognitionProgress.please_wait')}</span> 
        </div>
        <LinearProgress 
            variant="determinate" 
            value={currentProgress} 
            className={styles.progress_bar}
        />
      </div>
    );
  };
  
  export { RecognitionProgress };