// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 481px) {
    .graySpace_wrapper__RI9H1 {
        display: flex;
        padding: 32px 100px;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
    }

    .graySpace_empty_space__hGkOG {
        width: 1240px;
        height: 200px;
        border-radius: 16px;
        background: var(--gradient-placeholder-grey);
    }
}

@media (max-width: 480px) {
    .graySpace_wrapper__RI9H1 {
        display: flex;
        justify-content: center;
    }

    .graySpace_empty_space__hGkOG {
        display: flex;
        width: 296px;
        height: 120px;
        padding: 16px;
        margin: 16px;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 16px;
        background: var(--gradient-placeholder-grey);
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/graySpace.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,uBAAuB;QACvB,SAAS;QACT,mBAAmB;IACvB;;IAEA;QACI,aAAa;QACb,aAAa;QACb,mBAAmB;QACnB,4CAA4C;IAChD;AACJ;;AAEA;IACI;QACI,aAAa;QACb,uBAAuB;IAC3B;;IAEA;QACI,aAAa;QACb,YAAY;QACZ,aAAa;QACb,aAAa;QACb,YAAY;QACZ,uBAAuB;QACvB,SAAS;QACT,cAAc;QACd,mBAAmB;QACnB,4CAA4C;IAChD;AACJ","sourcesContent":["@media (min-width: 481px) {\r\n    .wrapper {\r\n        display: flex;\r\n        padding: 32px 100px;\r\n        justify-content: center;\r\n        align-items: flex-start;\r\n        gap: 10px;\r\n        align-self: stretch;\r\n    }\r\n\r\n    .empty_space {\r\n        width: 1240px;\r\n        height: 200px;\r\n        border-radius: 16px;\r\n        background: var(--gradient-placeholder-grey);\r\n    }\r\n}\r\n\r\n@media (max-width: 480px) {\r\n    .wrapper {\r\n        display: flex;\r\n        justify-content: center;\r\n    }\r\n\r\n    .empty_space {\r\n        display: flex;\r\n        width: 296px;\r\n        height: 120px;\r\n        padding: 16px;\r\n        margin: 16px;\r\n        align-items: flex-start;\r\n        gap: 10px;\r\n        flex-shrink: 0;\r\n        border-radius: 16px;\r\n        background: var(--gradient-placeholder-grey);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `graySpace_wrapper__RI9H1`,
	"empty_space": `graySpace_empty_space__hGkOG`
};
export default ___CSS_LOADER_EXPORT___;
