import styles from "../styles/errorModal.module.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Modal } from "@mui/material";
import ReactGA from 'react-ga4';

const ErrorModal = ({ t, setStateArray, stateAfterCloseArray, response_guid, payment_uuid }) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        ReactGA.event({
            category: 'alert',
            action: 'critical_error',
            label: `${response_guid ? `rguid: ${response_guid}` : (payment_uuid ? `puuid: ${payment_uuid}` : 'no_data')}`,
        });
    }, [])

    const handleClose = () => {
        // Выполнение всех пар setState(stateAfterClose)
        setStateArray.forEach((setState, index) => {
          const stateAfterClose = stateAfterCloseArray[index];
          setState(stateAfterClose);
        });
    }

    return (
        <Modal 
            open={open}
            onClose={handleClose}
        >
            <div className = {styles.modal}>
                <div className={styles.result_page}>
                    <ErrorOutlineIcon className={styles.error_icon}/>
                    <span className={styles.text}>{t('error.smth_went_wrong')}</span>
                    <span className={styles.text}>
                        {t('error.write_us')} 
                        {" "}
                        <Link className={styles.feedback_link} to="/feedback">{t('error.feedback')}</Link>
                        {", "}
                        {t('error.other_ways_to_contact')} 
                        {": "}
                        <a className={styles.feedback_link} href={`mailto:${t('footer.email')}`}>
                            {t('footer.email')}
                        </a>
                    </span>
                </div>
                <button
                    onClick={handleClose}
                    className={styles.close_button}
                >
                    {t('error.close')}
                </button>
            </div>   
        </Modal>
    )
}

export { ErrorModal };