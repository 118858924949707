import React, { useEffect } from "react";
import { YOOKASSA_URL_PATH as YOOKASSA_SCRIPT_PATH } from "../constants/pathConstants";
import { RESULTS_FRONTEND_PATH as RESULTS_PATH } from "../constants/pathConstants";

const YooKassa = ({confirmation_token, request_guid, setOpen}) => {
  const resultsURL=`${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${RESULTS_PATH}`

  useEffect(() => {
    const script = document.createElement('script');
    script.src = YOOKASSA_SCRIPT_PATH;
    script.async = true;

    script.onload = () => {
        const checkout = new window.YooMoneyCheckoutWidget({
            confirmation_token: confirmation_token,
            return_url: `${resultsURL}/${request_guid}`,
            customization: {
              modal: true
            },
            error_callback: function(error) {
                console.log(error);
            }
        });

        checkout.on('success', () => {
          checkout.destroy();
          setOpen(false);
        })

        checkout.on('fail', () => {
          checkout.destroy();
          setOpen(false);
        })

        checkout.on('modal_close', () => {
          setOpen(false);
        });

        checkout.render('payment-form');
    };

    document.head.appendChild(script); 
    
    return () => {
      document.head.removeChild(script);
    };
}, []);


  return (<div id="payment-form"/>)
}

  export { YooKassa };