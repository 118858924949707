// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 481px) {
    .products_products__TLJxs {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
        padding-top: 50px;
    }

    .products_table__o-qpY, tr, thead{
        border: 1px dashed black;
        padding: 8px 10px;
        text-align: center;
        font-size: 22px;
        font-family: PT Sans;
    }

    .products_table__o-qpY, td {
        border: 1px dashed black;
        padding: 8px 10px;
        text-align: center;
        font-size: 20px;
        background: var(--white);
        font-family: PT Sans;
    }
}

@media (max-width: 480px) {
    .products_products__TLJxs {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
        padding: 16px;
    }

    .products_table__o-qpY, tr, thead{
        border: 1px dashed black;
        padding: 8px 10px;
        text-align: center;
        font-size: 20px;
        font-family: PT Sans;
    }

    .products_table__o-qpY, td {
        border: 1px dashed black;
        padding: 8px 10px;
        text-align: center;
        font-size: 18px;
        background: var(--white);
        font-family: PT Sans;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/products.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;QAClB,aAAa;QACb,eAAe;QACf,sBAAsB;QACtB,qBAAqB;QACrB,iBAAiB;IACrB;;IAEA;QACI,wBAAwB;QACxB,iBAAiB;QACjB,kBAAkB;QAClB,eAAe;QACf,oBAAoB;IACxB;;IAEA;QACI,wBAAwB;QACxB,iBAAiB;QACjB,kBAAkB;QAClB,eAAe;QACf,wBAAwB;QACxB,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,aAAa;QACb,eAAe;QACf,sBAAsB;QACtB,qBAAqB;QACrB,aAAa;IACjB;;IAEA;QACI,wBAAwB;QACxB,iBAAiB;QACjB,kBAAkB;QAClB,eAAe;QACf,oBAAoB;IACxB;;IAEA;QACI,wBAAwB;QACxB,iBAAiB;QACjB,kBAAkB;QAClB,eAAe;QACf,wBAAwB;QACxB,oBAAoB;IACxB;AACJ","sourcesContent":["@media (min-width: 481px) {\r\n    .products {\r\n        text-align: center;\r\n        display: flex;\r\n        flex-wrap: wrap;\r\n        flex-direction: column;\r\n        align-content: center;\r\n        padding-top: 50px;\r\n    }\r\n\r\n    .table, tr, thead{\r\n        border: 1px dashed black;\r\n        padding: 8px 10px;\r\n        text-align: center;\r\n        font-size: 22px;\r\n        font-family: PT Sans;\r\n    }\r\n\r\n    .table, td {\r\n        border: 1px dashed black;\r\n        padding: 8px 10px;\r\n        text-align: center;\r\n        font-size: 20px;\r\n        background: var(--white);\r\n        font-family: PT Sans;\r\n    }\r\n}\r\n\r\n@media (max-width: 480px) {\r\n    .products {\r\n        text-align: center;\r\n        display: flex;\r\n        flex-wrap: wrap;\r\n        flex-direction: column;\r\n        align-content: center;\r\n        padding: 16px;\r\n    }\r\n\r\n    .table, tr, thead{\r\n        border: 1px dashed black;\r\n        padding: 8px 10px;\r\n        text-align: center;\r\n        font-size: 20px;\r\n        font-family: PT Sans;\r\n    }\r\n\r\n    .table, td {\r\n        border: 1px dashed black;\r\n        padding: 8px 10px;\r\n        text-align: center;\r\n        font-size: 18px;\r\n        background: var(--white);\r\n        font-family: PT Sans;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"products": `products_products__TLJxs`,
	"table": `products_table__o-qpY`
};
export default ___CSS_LOADER_EXPORT___;
