import React, { createContext, useContext, useState } from 'react';

const CouponContext = createContext();

export const CouponProvider = ({ children }) => {
  const [globalCoupon, setGlobalCoupon] = useState(null);

  return (
    <CouponContext.Provider value={{ globalCoupon, setGlobalCoupon }}>
      {children}
    </CouponContext.Provider>
  );
};

export const useCoupon = () => {
  return useContext(CouponContext);
};