import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from "../styles/resultsCard.module.css";

import axios from "axios";

import { SPEC_RESULT_URL_PATH as RESULT_PATH } from "../constants/pathConstants";

const ResultsCard = ({ t, resultGUID }) => {
    const [resultData, setResultData] = useState(null);
    const resultURL = `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${RESULT_PATH}`
    const [isMobile, setIsMobile] = useState(window.screen.width <= 480);
    const [loadingAttribute, setLoadingAttribute] = useState("lazy");

    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;

        if (currentPath.startsWith('/results_util'))
           setLoadingAttribute("eager"); 
    }, [location.pathname]);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.screen.width <= 480);
      };
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
        if (resultGUID) {
            getSpecifiedResult();
        }
    }, [resultGUID]);

    const getSpecifiedResult = async () => {
        try {
            const res = await axios.request({
                url: `${resultURL}/${resultGUID}`, 
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (res.data) {
                setResultData(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
        {resultData ?  
            <div className={styles.card}>
                <div className={styles.img_wrapper}>
                    <div className={styles.img_fitter}>
                        {resultData.image ?
                        <img 
                            src={`data:image/${resultData.image_ext};base64,${resultData.image}`} 
                            className={styles.img}
                            alt="Result Image"
                            loading={loadingAttribute}
                        />
                        :
                        <img 
                            src={`${process.env.PUBLIC_URL}/broken_image.png`} 
                            alt="Logo" 
                        />
                        }
                    </div>
                </div>
                <div className={styles.card_field}>
                    <div className={styles.card_text_container}>
                        <span className={styles.card_info_text}>
                            {t('resultsCard.source')}
                        </span>
                        <span className={styles.card_result_text}>
                            {resultData.Site}
                        </span>
                    </div>

                    <div className={styles.card_text_container}>
                        <span className={styles.card_info_text}>
                            {t('resultsCard.site_link')}
                        </span>
                        <a href={resultData.Page} 
                            target={isMobile ? undefined : "_blank"}
                            rel={isMobile ? undefined : "noopener noreferrer"}
                            className={styles.card_site_link}
                        >
                            {resultData.Page}
                        </a>
                    </div>

                    <div className={styles.card_text_container}>
                        <span className={styles.card_info_text}>
                            {t('resultsCard.retrieval_date')}
                        </span>
                        <span className={styles.card_result_text}>
                            {resultData.discovery_date ?
                                resultData.discovery_date
                                :
                                t('resultsCard.untracked_date')
                            }
                        </span>
                    </div>
                </div>
            </div>
            :
            ""
        }
        </>
    );
};

export {ResultsCard};