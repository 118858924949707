import React from "react";
import styles from "../styles/photosherlock.module.css";
import Logo from '../images/Logo.svg';

const PhotoSherlock = ({ className }) => {
    return(
        <div className={styles.wrapper}>
            <img
                className={className}
                src={Logo} 
                alt="Logo" 
            />
        </div>
    );
}

export { PhotoSherlock }