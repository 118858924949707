// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* global variable for library react-flag-select. 
    This component has a button object inside and it has id="rfs-btn". */

#rfs-btn {
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/flagSelect.css"],"names":[],"mappings":"AAAA;wEACwE;;AAExE;IACI,uBAAuB;AAC3B","sourcesContent":["/* global variable for library react-flag-select. \r\n    This component has a button object inside and it has id=\"rfs-btn\". */\r\n\r\n#rfs-btn {\r\n    background-color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
