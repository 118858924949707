import React, { useEffect, useState} from "react";
import { Link } from 'react-router-dom';
import { UsersPage } from './users';
import { Carousel } from './carousel';
import styles from "../styles/homePage.module.css";

import Face from '../images/Face.webp';
import Upload from '../images/UploadHmPg.svg';
import Hand from '../images/Hand.svg';

import Mesh from '../images/Mesh.webp';
import MagicWand from '../images/MagicWand.svg';

import FoundPhoto1 from '../images/Social.webp';
import FoundPhoto2 from '../images/OnSite.webp';
import FoundPhoto3 from '../images/Onlik.webp';
import TinderLogo from '../images/Tinder.svg';
import GlobeLogo from '../images/Globe.svg';
import OnlyFansLogo from '../images/OnlyFans.svg';
import Target from '../images/TargetHmPg.svg';
import Search from '../images/Search.svg';

import Safe from '../images/Safe.svg';
import Fast from '../images/Fast.svg';
import Effective from '../images/Effective.svg';

import Analytics from '../images/Analytics.svg';
import DB from '../images/Db.svg';
import Select from '../images/Select.svg';

import HiTech from '../images/Hi-Tech.png'

import SmallGallery from '../images/Small_Gallery.webp';
import MobileSmallGallery from '../images/Mobile_Small_Gallery.webp';

import Chaturbate from '../images/Ctb.svg';
import Tinder from '../images/Tndr.png';
import BongaCams from '../images/BongaCams.png';
import XHamster from '../images/XHamster.svg';
import XVideos from '../images/XVideos.svg';
import StripChat from '../images/Stripchat.svg';
import PornHub from '../images/Prnhb.svg';
import MyDirtyHobby from '../images/Mdrthbb.svg';
import ManyVids from '../images/ManyVids.svg';
import Pure from '../images/Pure.svg';
import VKDating from '../images/VKDating.svg';
import Mamba from '../images/Mamba.svg';

import { ReviewCard } from "./reviewCard";
import Avatar1 from '../images/Avatar1.png';
import Avatar2 from '../images/Avatar2.png';
import Avatar3 from '../images/Avatar3.png';
import Avatar4 from '../images/Avatar4.png';

import Family from '../images/family.webp';

import { FIND_FRONTEND_PATH as FIND_PATH } from "../constants/pathConstants";
import { useCoupon } from './couponProvider';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

const HomePage = ({ t }) => {
    const [isMobile, setIsMobile] = useState(window.screen.width <= 480);
    const { setGlobalCoupon } = useCoupon();
    const { couponCode } = useParams();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Главная страница" });
        const handleResize = () => {
            setIsMobile(window.screen.width <= 480);
        };
        window.addEventListener('resize', handleResize);
  
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (couponCode) {
            setGlobalCoupon(couponCode);
        }
    }, [couponCode]);

    const sendGA = (label) => {
        ReactGA.event({
            category: 'button',
            action: `${label}_clicked`,
        });
    };

    const findRelevanceText = isMobile
    ? t('homePage.work_explanation.find_relevance_mobile')
    : t('homePage.work_explanation.find_relevance');

    const telegramChannelsText = isMobile
    ? t('homePage.data_sources.tg_channels_mobile')
    : t('homePage.data_sources.tg_channels');

    const webcamSitesText = isMobile
    ? t('homePage.data_sources.webcam_sites_mobile')
    : t('homePage.data_sources.webcam_sites');

    const onlyFansPhotoText = isMobile
    ? t('homePage.data_sources.onlyfans_photos_mobile')
    : t('homePage.data_sources.onlyfans_photos');

    const adultModelsPhotoText = isMobile
    ? t('homePage.data_sources.models_18+_mobile')
    : t('homePage.data_sources.models_18+');

    const advantagesCarousel= [
        <div key={1} className={styles.card}>
            <img
                className={styles.safelogo_size}
                src={Safe} 
                alt="Safe"
            />
            <span className={styles.advantages_title_text}>{t('homePage.advantages.safe')}</span>
            <div className={styles.card_info_wrapper}>
                <div className={styles.safe_card_info_wrapper}>
                    <span className={styles.card_info_text}>{t('homePage.advantages.safe.dont_save_data')}</span>
                </div>
                <div className={styles.safe_card_info_wrapper}>
                    <span className={styles.card_info_text}>{t('homePage.advantages.safe.dont_store_photo')}</span>
                </div>
            </div>
        </div>,
        <div key={2} className={styles.card}>
            <img
                className={styles.fastlogo_size}
                src={Fast} 
                alt="Fast"
            />
            <span className={styles.advantages_title_text}>{t('homePage.advantages.fast')}</span>
            <div className={styles.card_info_wrapper}>
                <div className={styles.fast_card_info_wrapper}>
                    <span className={styles.card_info_text}>{t('homePage.advantages.fast.one_photo_is_enough')}</span>
                </div>
                <div className={styles.fast_card_info_wrapper}>
                    <span className={styles.card_info_text}>{t('homePage.advantages.fast.fast_result')}</span>
                </div>
            </div>
        </div>,
        <div key={3} className={styles.card}>
            <img
                className={styles.effectivelogo_size}
                src={Effective} 
                alt="Effective"
            />
            <span className={styles.advantages_title_text}>{t('homePage.advantages.effective')}</span>
            <div className={styles.card_info_wrapper}>
                <div className={styles.effective_card_info_wrapper}>
                    <span className={styles.card_info_text}>{t('homePage.advantages.effective.high_accuracy')}</span>
                </div>
                <div className={styles.effective_card_info_wrapper}>
                    <span className={styles.card_info_text}>{t('homePage.advantages.effective.lots_of_data_sources')}</span>
                </div>
            </div>
        </div>
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.title_wrapper}>
                <div>
                <div className={styles.inner_wrapper}>
                    <div className={styles.main_title_container}>
                        <span className={styles.main_title_text}>
                            {t('homePage.title.find_relevances_by_photo')}
                        </span>
                        <span className={styles.main_title_description}>
                            {t('homePage.title.what_we_can_provide')}
                        </span>
                    </div>
                    <div className={styles.main_photo_frame_container}>
                        <div className={styles.main_photo_frame}>
                            <div className={styles.main_upload_photo_frame}>
                                <div className={styles.main_upload_photo_relative_container}>
                                    <img className={styles.main_upload_photo} src={Face} alt="Face"/>
                                    <img className={styles.main_upload_photo_hand} src={Hand} alt="Hand"/>
                                </div>
                            </div>
                            <div className={styles.main_photo_frame_text_wrapper}>
                                <img src={Upload} alt="Upload"/>
                                <span className={styles.main_photo_frame_text}>{t('homePage.title.upload_photo')}</span>
                            </div>
                        </div>
                        <div className={styles.neural_link_wrapper}>
                            <div className={styles.main_photo_frame_2}>
                                <img src={Mesh} alt="Neuro"/>
                                <div className={styles.main_photo_frame_text_wrapper}>
                                    <img src={MagicWand} alt="MagicWand"/>
                                    <span className={styles.main_photo_frame_text}>{t('homePage.title.neural_magic')}</span>
                                </div>
                            </div>
                            <div className={styles.main_photo_frame}>
                                <div className={styles.main_foundphoto_frame}>
                                    <div className={styles.main_foundphoto1_frame}>
                                        <div className={styles.main_foundphoto_wrapper}>
                                            <img className={styles.main_foundphoto_size} src={FoundPhoto1} alt="FoundPhoto1"/>
                                        </div>
                                        <div className={styles.main_foundphoto_result}>
                                            <img src={TinderLogo} alt="TinderLogo"/>
                                            <span className={styles.main_foundphoto_text}>tinder.com</span>
                                        </div>
                                    </div>
                                    <div className={styles.main_foundphoto23_frame}>
                                        <div className={styles.main_foundphoto2_frame_wrapper}>
                                            <div className={styles.main_foundphoto_wrapper}>
                                                <img className={styles.main_foundphoto_size} src={FoundPhoto2} alt="FoundPhoto2"/>
                                            </div>
                                            <div className={styles.main_foundphoto_result}>
                                                <img src={GlobeLogo} alt="GlobeLogo"/>
                                                <span className={styles.main_foundphoto_text}>dosug.su</span>
                                            </div>
                                        </div>
                                        <div className={styles.main_foundphoto3_frame_wrapper}>
                                            <div className={styles.main_foundphoto_wrapper}>
                                                <img className={styles.main_foundphoto_size} src={FoundPhoto3} alt="FoundPhoto3"/>
                                            </div>
                                            <div className={styles.main_foundphoto_result}>
                                                <img src={OnlyFansLogo} alt="OnlyFansLogo"/>
                                                <span className={styles.main_foundphoto_text}>onlyfans.com</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.main_photo_frame_text_wrapper}>
                                    <img src={Target} alt="Target"/>
                                    <span className={styles.main_photo_frame_text}>{t('homePage.title.rate_result')}</span>
                                </div>
                            </div>
                        <img src={HiTech} className={styles.img_neural_link} alt="Neural Link"/>
                        </div>
                    </div>
                    <Link className={styles.text_decoration_none} to={FIND_PATH}>
                        <button 
                            className={styles.homepage_button}
                            onClick={() => sendGA('top_search_button')}
                        >
                            <img className={styles.white_icon} src={Search} alt="Search"/>
                            <span className={styles.homepage_button_text}>{t('homePage.title.try')}</span>
                        </button>
                    </Link>
                </div>
                </div>
            </div>

            <div className={styles.width}>
            <div className={styles.info_wrapper}>

                {/* Advantages */}
                <div className={styles.advantages_wrapper}>
                    {isMobile ? 
                        <Carousel items={advantagesCarousel}/>
                    :
                        <>
                        <div className={styles.card}>
                            <img
                                src={Safe} 
                                alt="Safe"
                            />
                            <span className={styles.advantages_title_text}>{t('homePage.advantages.safe')}</span>
                            <div className={styles.card_info_wrapper}>
                                <div className={styles.safe_card_info_wrapper}>
                                    <span className={styles.card_info_text}>{t('homePage.advantages.safe.dont_save_data')}</span>
                                </div>
                                <div className={styles.safe_card_info_wrapper}>
                                    <span className={styles.card_info_text}>{t('homePage.advantages.safe.dont_store_photo')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <img
                                src={Fast} 
                                alt="Fast"
                            />
                            <span className={styles.advantages_title_text}>{t('homePage.advantages.fast')}</span>
                            <div className={styles.card_info_wrapper}>
                                <div className={styles.fast_card_info_wrapper}>
                                    <span className={styles.card_info_text}>{t('homePage.advantages.fast.one_photo_is_enough')}</span>
                                </div>
                                <div className={styles.fast_card_info_wrapper}>
                                    <span className={styles.card_info_text}>{t('homePage.advantages.fast.fast_result')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <img
                                src={Effective} 
                                alt="Effective"
                            />
                            <span className={styles.advantages_title_text}>{t('homePage.advantages.effective')}</span>
                            <div className={styles.card_info_wrapper}>
                                <div className={styles.effective_card_info_wrapper}>
                                    <span className={styles.card_info_text}>{t('homePage.advantages.effective.high_accuracy')}</span>
                                </div>
                                <div className={styles.effective_card_info_wrapper}>
                                    <span className={styles.card_info_text}>{t('homePage.advantages.effective.lots_of_data_sources')}</span>
                                </div>
                            </div>
                        </div> 
                        </>
                }
                </div>

                {/* Users */}
                <UsersPage t={t}/>

                {/* How it works */}
                <div className={styles.work_explanation_wrapper}>
                    <div className={styles.work_explanation_canvas_wrapper}>
                        <div className={styles.work_explanation_text_card}>
                            <span className={styles.work_explanation_title}>{t('homePage.work_explanation.how_it_works')}</span>
                            <span className={styles.work_explanation_desc}>{t('homePage.work_explanation.description')}</span>
                        </div>
                        <div className={styles.work_explanation_abstract_card}>
                            <div className={styles.work_explanation_img_card}>
                                <img
                                    src={Analytics} 
                                    alt="Analytics"
                                    className={styles.work_explanation_img}
                                />
                                <span className={styles.work_explanation_small_img_card_text}>{t('homePage.work_explanation.analyse_photo')}</span>
                            </div>
                            <div className={styles.work_explanation_img_card}>
                                <img
                                    src={DB} 
                                    alt="Database"
                                    className={styles.work_explanation_img}
                                />
                                <span className={styles.work_explanation_img_card_text}>{t('homePage.work_explanation.checks_with_face_encodings')}</span>  
                            </div>
                            <div className={styles.work_explanation_img_card}>
                                <img
                                    src={Select} 
                                    alt="Select"
                                    className={styles.work_explanation_img}
                                /> 
                                <span className={styles.work_explanation_img_card_text}>{findRelevanceText}</span>
                            </div>
                            <div className={styles.work_explanation_result_card}>
                                <span className={styles.accuracy_number_text}>99%</span> 
                                <span className={styles.accuracy_text}>{t('homePage.work_explanation.probability')}</span>
                                <span className={styles.accuracy_text}>{t('homePage.work_explanation.match')}</span>    
                            </div>
                        </div>
                    </div>
                </div>

                {/* Our mission */}
                <div className={styles.mission_wrapper}>
                    <div className={styles.mission_text_wrapper}>
                        <span className={styles.mission_title}>{t('homePage.mission.title')}</span>
                        <span className={styles.mission_description}>
                            {t('homePage.mission.description_1')}
                        </span>
                        <div className={styles.mission_mobile_gallery}>
                            <img
                                className={styles.mission_img_size}
                                src={MobileSmallGallery}
                                alt="Mobile Small Gallery"
                            />
                        </div>
                        <span className={styles.mission_description}>
                            {t('homePage.mission.description_2')}
                        </span>
                    </div>
                    <div className={styles.mission_desktop_gallery}>
                        <img
                            className={styles.mission_img_size}
                            src={SmallGallery}
                            alt="Small Gallery"
                        />
                    </div>
                </div>

                {/* Data sources */}
                <div className={styles.data_sources_wrapper}>
                    <div className={styles.sources_text_wrapper}>
                        <span className={styles.sources_title}>{t('homePage.data_sources.title')}</span>
                        <div className={styles.sources_container_wrapper}>
                                <div className={styles.sources_blue_container}>
                                    <span className={styles.sources_container_text}>{telegramChannelsText}</span>
                                </div>
                                <div className={styles.sources_green_container}>
                                    <span className={styles.sources_container_text}>{webcamSitesText}</span>
                                </div>
                                <div className={styles.sources_red_container}>
                                    <span className={styles.sources_container_text}>{onlyFansPhotoText}</span>
                                </div>
                                <div className={styles.sources_yellow_container}>
                                    <span className={styles.sources_container_text}>{adultModelsPhotoText}</span>
                                </div>
                                <div className={styles.sources_purple_container}>
                                    <span className={styles.sources_container_text}>{t('homePage.data_sources.intimate')}</span>
                                </div>
                        </div>
                    </div>
                    <div className={styles.sources_bottom_wrapper}>
                        <div className={styles.sources_logo_wrapper}>
                            <img className={styles.chaturbate_size} src={Chaturbate} alt="Chaturbate"/>
                            <img className={styles.tinder_size} src={Tinder} alt="Tinder"/>
                            <img className={styles.bongacams_size} src={BongaCams} alt="BongaCams"/>
                            <img className={styles.xhamster_size} src={XHamster} alt="BongaCams"/>

                            <img className={styles.xvideos_size} src={XVideos} alt="XVideos"/>
                            <img className={styles.stripchat_size} src={StripChat} alt="StripChat"/>
                            <img className={styles.pornhub_size} src={PornHub} alt="PornHub"/>
                            <img className={styles.mydirtyhobby_size} src={MyDirtyHobby} alt="MyDirtyHobby"/>

                            <img className={styles.manyvids_size} src={ManyVids} alt="ManyVids"/>
                            <img className={styles.mamba_size} src={Mamba} alt="Mamba"/>
                            <img className={styles.pure_size} src={Pure} alt="Pure"/>
                            <img className={styles.vkdating_size} src={VKDating} alt="VKDating"/>
                        </div>
                        <div className={styles.sources_logo_fade}/>
                    </div>
                </div>

                {/* Reviews */}
                <div className={styles.reviews_wrapper}>
                    <span className={styles.reviews_title}>{t('homePage.reviews.title')}</span>
                    <div className={styles.reviews_container}>
                        <ReviewCard t={t} img={Avatar1} id={978}  comment={t('homePage.reviews.rw1')} ratingValue={5}/>
                        <ReviewCard t={t} img={Avatar2} id={1473} comment={t('homePage.reviews.rw2')} ratingValue={1}/>
                        <ReviewCard t={t} img={Avatar3} id={2452} comment={t('homePage.reviews.rw3')} ratingValue={3}/>
                        <ReviewCard t={t} img={Avatar4} id={2891} comment={t('homePage.reviews.rw4')} ratingValue={4}/>
                    </div>
                </div>

                {/* Bottom Search */}
                <div className={styles.bottom_search_wrapper}>
                    <div>
                        <img className={styles.bottom_search_family_wrapper} src={Family} alt="Family"/>
                    </div>
                    <div className={styles.bottom_search_right_side_wrapper}>
                        <span className={styles.bottom_search_text}>
                            {t('homePage.bottom_search.motivation_to_search')}
                        </span>
                        <Link className={styles.text_decoration_none} to={FIND_PATH}>
                            <button
                                className={styles.homepage_button}
                                onClick={() => sendGA('bottom_search_button')}
                            >
                                <span className={styles.homepage_button_text}>{t('homePage.bottom_search.start_searching')}</span>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export { HomePage };