import React from "react";
import styles from "../styles/graySpace.module.css";

const GraySpace = () => {


    return (
        <div className={styles.wrapper}>
            <div className={styles.empty_space}/>
        </div>
    );
}

export { GraySpace };