import React, { useEffect, useState } from "react";
import styles from "../styles/dataProcessor.module.css";
import { Payments } from "./payments";
import { Coupon } from "./coupon";
import { ErrorModal } from "./errorModal";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";

import { REQUEST_ID_URL_PATH as UPLOAD_PATH } from "../constants/pathConstants";
import { RECOGNIZE_PHOTOS_URL_PATH as PHOTOS_PATH } from "../constants/pathConstants";
import { STATUS_URL_PATH as STATUS_PATH } from "../constants/pathConstants";

import MirLogo from '../images/MIR.svg';
import MastercardLogo from '../images/Mastercard.svg';
import VisaLogo from '../images/Visa.svg';
import SberpayLogo from '../images/SberPay.png';
import YoomoneyLogo from '../images/Yoomoney.svg';

import {ReactComponent as UpRightArrow} from "../images/Up_Right_Arrow.svg"

import * as GATEWAY from '../constants/gatewayConstants';
import ReactGA from 'react-ga4';

const DataProcessor = (props) => {
    const [isOpen,setOpen] = useState(false);
    const [response, setResponse] = useState([]);
    const [price, setPrice] = useState(null);
    const [paymentGateway, setPaymentGateway] = useState(GATEWAY.YOOKASSA_GATEWAY)

    const [couponDiscount, setCouponDiscount] = useState(0);
    const [couponActive, setCouponActive] = useState(false);
    const [couponPrice, setCouponPrice] = useState(null);
    
    const [isPriceLoading, setIsPriceLoading] = useState(true);
    const [isCouponLoading, setIsCouponLoading] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isFail, setFail] = useState(false);

    //currently product_id is hardcoded, since we have only one product 'Photo recoginition'
    const product_id = 1; 

    const getProductPrice = async () => {
        try {
            setPrice([]);
            const res = await axios.request({
                url: props.productURL, 
                method: "GET",
                params: {id: product_id},
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setPrice(res.data.price);
            setIsPriceLoading(false);
        } catch (error) {console.log(error);}
    }

    useEffect(() => {
        getProductPrice();
      }, []);

    useEffect(() => {
        if (props.couponCode) {
            setIsCouponLoading(true);
        }
    }, [])

    useEffect(() => {
        if (isOpen === false) {
            setIsLoading(false);
        }
    }, [isOpen])

    const sendGA = (label) => {
        ReactGA.event({
          category: 'button',
          action: `${label}_clicked`,
        });
    };

    const handlePost = async () => {
        try {
            setIsLoading(true);
            let data_fields = {
                product_id: product_id, 
                capture: true,
                confirmation: {
                    "type": "embedded"
                },
                request_guid: props.requestGUID
            };

            if (props.lng.startsWith('ru')) {}
            else {data_fields.confirmation.locale = "en_US";}
            if (couponActive) {data_fields.coupon_code = props.couponCode}
            const data = JSON.stringify(data_fields);

            setResponse([]);
            const res = await axios.request({
                url: props.paymentURL, 
                method: "POST",
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            setResponse(res.data);
            setIsLoading(false);
        } catch (error) {console.log(error); setOpen(false); setFail(true)}
    };

    const handleCouponActive = (discount, coupon_status) => {
        setCouponDiscount(discount);
        setCouponActive(coupon_status);
    }

    useEffect(() => {
        if (!isCouponLoading && !isPriceLoading) {
            setCouponPrice(price * (100-couponDiscount)/100);
            if (couponDiscount === 100) {
                setPaymentGateway(GATEWAY.NONE_GATEWAY);
            }
            else {
                setPaymentGateway(GATEWAY.YOOKASSA_GATEWAY); 
            }
        }
    }, [isPriceLoading, isCouponLoading, couponDiscount])

    return (
        <div className={styles.data_processor_wrapper}>
            <div className={styles.data_processor_container}>
                
                <div className={styles.payments_inner_wrapper}>
                    <span className={styles.data_processor_title}>{props.t('dataProcessor.pay_service')}</span>
                    {props.couponCode &&
                        <Coupon t={props.t} 
                                couponCode={props.couponCode}
                                couponURL={props.couponURL}
                                onCouponActive={handleCouponActive}
                                setIsCouponLoading={setIsCouponLoading}
                        />
                    }
                    {isPriceLoading || isCouponLoading ?
                        <div className={styles.progress}>
                            <CircularProgress size="64px"/>
                        </div>
                        :
                        <>
                            {couponActive ?
                            <>
                                <div className={styles.price_wrapper}>
                                    <span className={styles.previous_price}>{price} {props.t('dataProcessor.rubles')}</span>
                                        {Math.abs(couponPrice) < 0.001 ? 
                                            <span className={styles.current_price}>{props.t('dataProcessor.for_free')}</span>
                                        : 
                                            <span className={styles.current_price}>{`${couponPrice} ${props.t('dataProcessor.rubles')}`}</span>
                                        }
                                </div>
                            </>
                            :
                                <span className={styles.current_price}>{price} {props.t('dataProcessor.rubles')}</span> 
                            }
                            <div className={styles.pay_container}>
                                <button 
                                    className={`
                                        ${styles.pay_button}
                                        ${(isOpen || isLoading) && styles.disabled}
                                    `}
                                    onClick={() => {sendGA('pay'); handlePost(); setOpen(true)}}
                                >
                                    {couponActive && Math.abs(couponPrice) < 0.001 ? 
                                        <span className={styles.pay_button_text}>{props.t('dataProcessor.free_search')}</span>
                                    :
                                        <span className={styles.pay_button_text}>{props.t('dataProcessor.pay_button_text')}</span>
                                    }
                                    {isLoading ? 
                                        <CircularProgress size="24px"/>
                                        :
                                        <UpRightArrow className={styles.status}/>
                                    }

                                </button> 

                                <div className={styles.payment_methods_container}>
                                    <img src={MirLogo}/>
                                    <img src={MastercardLogo}/>
                                    <img src={VisaLogo}/>
                                    <img src={SberpayLogo}/>
                                    <img src={YoomoneyLogo}/>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div> 
            <div className={styles.absolute_position}>
                {isOpen === true && response.length !== 0 ? (
                    <Payments 
                        files = {props.files}
                        payment_response = {response}
                        requestGUID = {props.requestGUID}
                        paymentGateway = {paymentGateway}
                        uploadURL={ `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${UPLOAD_PATH}`}
                        statusURL={`${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${STATUS_PATH}`}
                        resultURL={`${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${PHOTOS_PATH}`}
                        t={props.t}
                        setOpen = {setOpen}
                    />
                ) : null}   
            </div>

            {isFail && <ErrorModal t={props.t} setState={setFail} stateAfterClose={false} payment_uuid={response}/>}
        </div>          
    )
}

export { DataProcessor };