import React, { useState, useEffect } from 'react';
import styles from "../styles/reviewCard.module.css";
import { Rating as MUIRating } from '@mui/material';

const ReviewCard = ({ t, img, id, comment, ratingValue }) => {
    const [isMobile, setIsMobile] = useState(window.screen.width <= 480);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.screen.width <= 480);
        };
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div className={styles.card}>
        {isMobile ? 
            <div className={styles.photouser_mobile}>
                <img className={styles.img} src={img}/>
                <span className={styles.user}>{`${t('homePage.reviews.user')} #${id}`}</span>
            </div>
        :
            <img className={styles.img} src={img}/>
        }
            <div className={styles.review}> 
            {!isMobile &&
                <span className={styles.user}>{`${t('homePage.reviews.user')} #${id}`}</span>
            }
                <span className={styles.text}>{comment}</span>
                <MUIRating
                    value={ratingValue}
                    size="large"
                    readOnly={true}
                />
            </div>
        </div>
    );
};

export { ReviewCard }