import { YooKassa } from "./yookassa";

import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';

import * as GATEWAY from '../constants/gatewayConstants';

const Payments = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (props.paymentGateway === GATEWAY.NONE_GATEWAY) {
            navigate(`/results/${props.requestGUID}`)
        }
    }, [props.paymentGateway])
    
    return ( 
        <div>
            {(props.paymentGateway === GATEWAY.YOOKASSA_GATEWAY) &&
                <YooKassa 
                    confirmation_token = {props.payment_response.confirmation_token}
                    request_guid={props.requestGUID}
                    setOpen={props.setOpen}
                /> 
            }
        </div>
    )
}

export { Payments };