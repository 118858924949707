import React from "react";
// import FlagsSelect from 'react-flags-select';
import "../styles/flagSelect.css"
import styles from "../styles/header.module.css";
import { Link } from 'react-router-dom';
import { PhotoSherlock } from "./photosherlock";
import { HamburgerMenu } from "./hamburgerMenu";
import ReactGA from 'react-ga4';

const Header = ({ t, lng, changeLanguage, ref }) => {
  // Convert language code to country code
  const selectedCountry = lng.startsWith('ru') ? 'RU' : 'GB';
  
  const handleChange = (countryCode) => {
    // Convert country code back to language code
    const languageCode = countryCode === 'RU' ? 'ru' : 'en';
    changeLanguage(languageCode);
  };

  const sendGA = (label) => {
    ReactGA.event({
      category: 'button',
      action: `${label}_clicked`,
    });
  };
  
  return (
    <header className={styles.header} id="header">
      <div className={styles.main_router}>
        <div className={styles.hamburger}>
          <HamburgerMenu t={t}/>
        </div>
        <Link className={styles.no_underline} to="/" onClick={() => sendGA('homepage_button')}>
          <PhotoSherlock className={styles.img}/>
        </Link>
      </div>
      <div className={styles.div}>
        <Link className={styles.link_text} to="/products">{t('header.productlist')}</Link>
        <Link className={styles.link_text} to="/feedback">{t('header.feedback')}</Link>
        <Link className={styles.button_wrapper} to="/find" onClick={() => sendGA('header_search_button')}>
          <div className={styles.search_text}>{t('header.search')}</div>
        </Link>
      </div>
              {/* TODO: снизу код для переключения языка. В будущем понадобится */}
              {/* <div className={styles.rightside}>
                <FlagsSelect 
                  selected={selectedCountry}
                  countries={["RU", "GB"]}
                  customLabels={{ "GB": t('header.english'), "RU": t('header.russian') }}
                  onSelect={handleChange}
                />
              </div> */}
    </header>
  );
}

export { Header };