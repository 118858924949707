// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./PTSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./PTSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./PTSans-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./PTSans-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'PT Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PT Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'PT Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'PT Sans';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: bold;
    font-style: italic;
  }`, "",{"version":3,"sources":["webpack://./src/fonts/pt-sans.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,+DAAmD;IACnD,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,+DAAgD;IAChD,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,+DAAkD;IAClD,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,+DAAsD;IACtD,iBAAiB;IACjB,kBAAkB;EACpB","sourcesContent":["@font-face {\r\n    font-family: 'PT Sans';\r\n    src: url('./PTSans-Regular.ttf') format('truetype');\r\n    font-weight: normal;\r\n    font-style: normal;\r\n  }\r\n  \r\n  @font-face {\r\n    font-family: 'PT Sans';\r\n    src: url('./PTSans-Bold.ttf') format('truetype');\r\n    font-weight: bold;\r\n    font-style: normal;\r\n  }\r\n  \r\n  @font-face {\r\n    font-family: 'PT Sans';\r\n    src: url('./PTSans-Italic.ttf') format('truetype');\r\n    font-weight: normal;\r\n    font-style: italic;\r\n  }\r\n\r\n  @font-face {\r\n    font-family: 'PT Sans';\r\n    src: url('./PTSans-BoldItalic.ttf') format('truetype');\r\n    font-weight: bold;\r\n    font-style: italic;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
