import React, { Fragment, useEffect } from "react";
import styles from "../styles/alert.module.css";
import * as ALERT_STATUS from '../constants/alertStatuses';
import ReactGA from 'react-ga4';

const Alert = ({ status, titleText, descTextArray }) => {
    useEffect(() => {
        ReactGA.event({
            category: 'alert',
            action: `${status || ''}`,
            label: `${titleText || ''}`,
        });
    }, [])

    const titleStyleClass = `${styles.alert_title}
        ${status === ALERT_STATUS.SUCCESS && styles.success}
        ${status === ALERT_STATUS.ERROR && styles.error}
        ${status === ALERT_STATUS.INFO && styles.info}`;

    const descStyleClass = `${styles.alert_desc}
        ${status === ALERT_STATUS.SUCCESS && styles.success}
        ${status === ALERT_STATUS.ERROR && styles.error}
        ${status === ALERT_STATUS.INFO && styles.info}`;

    return (
        <div className={`
            ${styles.alert_container}
            ${status === ALERT_STATUS.SUCCESS && styles.success}
            ${status === ALERT_STATUS.ERROR && styles.error}
            ${status === ALERT_STATUS.INFO && styles.info}
        `}>
            <span className={titleStyleClass}>
                {titleText}
            </span>
            <span className={descStyleClass}> 
                {descTextArray.map((descText, index) => (
                    <Fragment key={index}>{descText}</Fragment>
                ))}
            </span>
        </div>
    )
}

export { Alert };
