import React, { useState, useEffect, useCallback } from 'react';
import styles from "../styles/editor.module.css";
import Delete from '../images/Delete.svg';
import Rotate from '../images/Rotate.svg';

import classNames from "classnames";
import * as FACECHECK_STATUS from '../constants/filePickerStatuses';
import * as ACTIVE_STATUS from '../constants/searchPageStatuses';

const Editor = ({onClear, onChange, status, setStatus, files, isActive}) => {
    const [image, setImage] = useState(null);
    const [rotation, setRotation] = useState(0);
    const [scale, setScale] = useState(0.78);

    const [fileData, setFileData] = useState(null);

    useEffect(() => {
        if (files) {
            const file = files[0].file;

            const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);

            const { name, type } = file;
            setFileData({ name, type });
        }
    }, [files]);

    const handleClear = useCallback(() => {
        onClear();
      }, []);

    const handleRotate = () => {
        setRotation((prevRotation) => (prevRotation + 90));
    };

    useEffect(() => {
        if (status === FACECHECK_STATUS.SAVE_IMG) {
            handleSave();
        }
    }, [status]);

    const handleSave = async() => {
        const img = new Image();
        img.src = image;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const normalizedRotation = rotation % 360;
        if (normalizedRotation === 90 || normalizedRotation === 270) {
            canvas.width = img.height;
            canvas.height = img.width;
        } else {
            canvas.width = img.width;
            canvas.height = img.height;
        }

        const rotateRads = normalizedRotation % 360 * Math.PI / 180;
        
        ctx.save();
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(rotateRads);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);
        ctx.restore();
        

        const { name, type } = fileData;

        await new Promise((resolve) => {
            canvas.toBlob((blob) => {
                const editedFile = new File([blob], name, { type });
                const fileArray = [editedFile];
                onChange(fileArray);
                resolve();
            }, type);
        })

        setStatus(FACECHECK_STATUS.START);
        setRotation(0); 
    };

    return (
        <div className={classNames(
            styles.wrapper,
            isActive === ACTIVE_STATUS.FILEPICKER && styles.active
          )}>
            <div className={styles.img_container}>
            {image && 
                <img 
                    className={styles.img}
                    src={image}
                    alt="Uploaded" 
                    style={{transform: `rotate(${rotation}deg) scale(${scale})`}}
                />
            }
            </div>
            <div className={styles.action_wrapper}>
                <button 
                    onClick={handleRotate}
                    className={classNames(styles.none_button,
                        status===FACECHECK_STATUS.WAITING ? 
                            "" : styles.disabled
                    )}
                >
                    <img src={Rotate} alt="Rotate"/>
                </button>
                <button 
                    onClick={handleClear}
                    className={classNames(styles.none_button,
                        status===FACECHECK_STATUS.START ? 
                            styles.disabled : ""
                    )}
                >
                    <img src={Delete} alt="Delete"/>
                </button>
            </div>
        </div>
      );
    };

export { Editor };