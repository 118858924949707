// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 481px) {
  .notFoundPage_error_container__EwCw- {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
    }
  
  .notFoundPage_error_code__6fa3T {
    font-size: 10rem; 
    font-family: PT Sans;
    font-weight: bold;
    color: var(--black); 
  }
  
  .notFoundPage_error_message__wDwLP {
    font-size: 1.5rem; 
    font-family: PT Sans;
    font-weight: bold;
    color: var(--black); 
  }
}

@media (max-width: 480px) {
  .notFoundPage_error_container__EwCw- {
    display: flex;
    padding: 150px 0;
    flex-direction: column;
    align-items: center;
  }

  .notFoundPage_error_code__6fa3T {
    font-size: 10rem; 
    font-family: PT Sans;
    font-weight: bold;
    color: #333; 
  }

  .notFoundPage_error_message__wDwLP {
    font-size: 1.5rem; 
    font-weight: bold;
    color: #333; 
  } 
}`, "",{"version":3,"sources":["webpack://./src/styles/notFoundPage.module.css"],"names":[],"mappings":"AAAA;EACE;MACI,aAAa;MACb,kBAAkB;MAClB,sBAAsB;MACtB,uBAAuB;IACzB;;EAEF;IACE,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,oBAAoB;IACpB,iBAAiB;IACjB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,aAAa;IACb,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;EACb;AACF","sourcesContent":["@media (min-width: 481px) {\r\n  .error_container {\r\n      display: flex;\r\n      text-align: center;\r\n      flex-direction: column;\r\n      justify-content: center;\r\n    }\r\n  \r\n  .error_code {\r\n    font-size: 10rem; \r\n    font-family: PT Sans;\r\n    font-weight: bold;\r\n    color: var(--black); \r\n  }\r\n  \r\n  .error_message {\r\n    font-size: 1.5rem; \r\n    font-family: PT Sans;\r\n    font-weight: bold;\r\n    color: var(--black); \r\n  }\r\n}\r\n\r\n@media (max-width: 480px) {\r\n  .error_container {\r\n    display: flex;\r\n    padding: 150px 0;\r\n    flex-direction: column;\r\n    align-items: center;\r\n  }\r\n\r\n  .error_code {\r\n    font-size: 10rem; \r\n    font-family: PT Sans;\r\n    font-weight: bold;\r\n    color: #333; \r\n  }\r\n\r\n  .error_message {\r\n    font-size: 1.5rem; \r\n    font-weight: bold;\r\n    color: #333; \r\n  } \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error_container": `notFoundPage_error_container__EwCw-`,
	"error_code": `notFoundPage_error_code__6fa3T`,
	"error_message": `notFoundPage_error_message__wDwLP`
};
export default ___CSS_LOADER_EXPORT___;
