import React, { useEffect, useState } from "react";
import styles from "../styles/rating.module.css";

import axios from "axios";
import { FEEDBACK_URL_PATH as FEEDBACK_PATH } from "../constants/pathConstants";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import CloseIcon from '@mui/icons-material/Close';
import { TextField, Button, Modal, IconButton, Typography } from '@mui/material';
import { Rating as MUIRating } from '@mui/material';
import ReactGA from 'react-ga4';

const Rating = ({t, requestGUID, isMobile}) => {
    const [ratingValue, setRatingValue] = useState(0);
    const [message, setMessage] = useState('');
    const [isFormValid, setIsFormValid] = useState(true);
    const maxLength = 600; //max symbols
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [response, setResponse] = useState([]);
    const [isRequestMade, setRequestMade] = useState(false);
    const [open, setOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    // Проверка валидности формы при каждом изменении сообщения
    useEffect(() => {
        if (message.length > maxLength) {
            setIsFormValid(false);
        } else {
            setIsFormValid(true);
        }
    }, [message]);

    useEffect(() => {
        if (ratingValue > 0) {
            ReactGA.event({
                category: 'rating',
                action: `rating_opened_${ratingValue}`,
                label: `${requestGUID}`,
            });
        }
    }, [ratingValue])

    const handleSubmit = async (currMessage) => {
        try{
          const data = JSON.stringify({
            message: currMessage,
            rating: ratingValue,
            requestGUID: requestGUID
          });
          const res = await axios.request({
            url: `${process.env.REACT_APP_POST_IMG_HOST}:${process.env.REACT_APP_HOST_PORT}${process.env.REACT_APP_HOST_PREFIX}${FEEDBACK_PATH}`, 
            method: "POST",
            data,
            headers: {
                'Content-Type': 'application/json'
            }
          })
          setFeedbackModalOpen(false);
          setSubmitted(true);
          setMessage('');
          
          setResponse(res.data.status.ok);
          setRequestMade(true);
          setOpen(true);
        } catch (error) {console.log(error);}
      }

      const successFeedback = ( 
        <div className={styles.result_page}>
            <CheckCircleOutlineIcon className={styles.success_icon}/>
            <Typography>{t('rating.succeeded')}</Typography>
        </div>
    )
    
    const errorFeedback = ( 
        <div className={styles.result_page}>
            <ErrorOutlineIcon className={styles.error_icon}/>
            <Typography>{t('rating.smth_went_wrong')}</Typography>
            <Typography>{t('rating.write_us_later')}</Typography>
        </div>
    )

    const labelToShow = isFocused ? 
        t('rating.rate_us_briefly_msg') 
    : 
        t('rating.rate_us_msg');  

    const handleCloseModalByScenario = (currMessage) => {
        handleSubmit(currMessage);
        setFeedbackModalOpen(false);
    };

    const handleCloseModalASAP = () => {
        const currMessage = (`${message}\n(Закрыто без нажатия на кнопку "Отправить")`);
        handleCloseModalByScenario(currMessage);
    };


    return (
        <>
        {/* Rating */}
        <div className={styles.rating_wrapper}>
            {isMobile ?
                null
            :
                <span className={styles.side_func_info}>
                    {t('rating.rate_us')}
                </span>
            }
            <MUIRating
                value={ratingValue}
                onChange={(event, newValue) => {
                    if (newValue !== null) {
                        setRatingValue(newValue);
                    }
                    setFeedbackModalOpen(true);
                }}
                size="large"
                readOnly={submitted ? true : false}
            />

            {feedbackModalOpen ?
                <Modal
                    open={feedbackModalOpen}
                    onClose={handleCloseModalASAP}
                >
                    <div className={styles.modal}>
                        <IconButton
                            className={styles.close_feedback_button}
                            onClick={handleCloseModalASAP}
                        >
                            <CloseIcon />
                        </IconButton>

                        <div className={styles.formStyle}>
                            <TextField
                                sx={{ whiteSpace: "pre-wrap"}}
                                name="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                label={<span style={{ whiteSpace: 'pre-wrap'}}>{labelToShow}</span>}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={6}
                                error={message.length > maxLength}
                                helperText={message.length > maxLength ? t('rating.limit_exceeded') : ""}
                            />
                            <Button 
                                type="button"
                                variant="contained" 
                                color="primary" 
                                size="large" 
                                disabled={!isFormValid}
                                onClick={() => handleCloseModalByScenario(message)}
                            >
                                {t('rating.submit')}
                            </Button>
                        </div>
                    </div>
                </Modal> : null
            }

            {isRequestMade ?
                <Modal 
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <div className = {styles.modal}>
                        {response ? successFeedback : errorFeedback}
                        <button
                            onClick={() => setOpen(false)}
                            className={styles.close_response_button}
                        >
                        {t('rating.close')}
                        </button>
                    </div>   
                </Modal> : null
                }
        </div>
        </> 
    )
}

export { Rating };