import React from "react";
import styles from "../styles/footer.module.css";
import AgeSign from '../images/Age_Sign.svg';

import { Link } from 'react-router-dom';

import Telegram from '../images/Telegram.svg';
import VK from '../images/VK.svg';
import { PhotoSherlock } from "./photosherlock";
import { ScrollToTop } from './scrollToTop';

const Footer = ({ t }) => {
    return (
        <div className={styles.footer}>
            <div className={styles.wrapper}>
                <div className={styles.div}>
                    <div className={styles.main_bar}>
                        <PhotoSherlock/>
                        <p className={styles.element}>
                            {t('footer.search_for_matches')}
                        </p>
                            <div className={styles.icons_div}>
                                <a className={styles.link_wrapper} href="https://t.me/FotoSherlock">
                                    <img
                                        src={Telegram} 
                                        alt="Telegram" 
                                    />
                                </a>
                                <a className={styles.link_wrapper} href="https://vk.com/fotosherlok">
                                    <img
                                        src={VK} 
                                        alt="VK" 
                                    />
                                </a>
                            </div>
                    </div>
                            <div className={styles.secondary_bar}>
                                <div className={styles.title}>
                                    {t('footer.info')}
                                </div>
                                <div>
                                <ScrollToTop>
                                    <p className={styles.text}>
                                        <Link className={styles.link_wrapper} to="/products">
                                            {t('footer.our_services')}
                                        </Link>
                                    </p>
                                    <p className={styles.text}>
                                        <Link className={styles.link_wrapper} to="/offer">
                                            {t('footer.terms')}
                                        </Link>
                                    </p>
                                    </ScrollToTop>
                                </div>
                            </div>
                            <div className={styles.secondary_bar}>
                                <div className={styles.title}>
                                    {t('footer.contacts')}
                                </div>
                                <div>
                                <p className={styles.text}>
                                    <a className={styles.link_wrapper} href={`mailto:${t('footer.email')}`}>
                                        {t('footer.email')}
                                    </a>
                                </p>
                                </div>
                            </div>
                    <div className={styles.icons_plus_wrapper}>
                        <img
                            src={AgeSign} 
                            alt="Age Sign" 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
  };

export { Footer };